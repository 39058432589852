/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import Flex from 'bundles/epic/data/defaults/Flex.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  is_excluded_from_goal_setting: boolean;
  learningAssistantEnabled: boolean;
  embeddedLtiCourses: any;
  aliceABEnabled: boolean;
  aliceCoursePageviewEnabled: boolean;
  sessionsV2EnrollmentEnabled: boolean;
  calendarSyncEnabled: boolean;
  learnerPendoEnabled: boolean;
  secondaryNavStartCollapsed: boolean;
  learnerServiceWorkerEnabled: boolean;
  useLocalStorageProgressOverrides: boolean;
  enableAnonymousPeerReviewAuthoring: boolean;
};

const NAMESPACE = 'Flex';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([Flex as $TSFixMe]);

const FlexEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default FlexEpicClient;
