/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect } from 'react';

import config from 'js/app/config';

import { Button, Grid, Hidden, TextField, Typography2 } from '@coursera/cds-core';
import {
  ChevronNextIcon,
  ChevronPreviousIcon,
  DownloadIcon,
  FullscreenTwoIcon,
  MinimizeScreenIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@coursera/cds-icons';

import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';

import _t from 'i18n!nls/cml';

const styles = {
  button: css`
    @media (max-width: 608px) {
      padding: var(--cds-spacing-100);
    }
  `,
  toolbar: css`
    padding: var(--cds-spacing-150) var(--cds-spacing-400);
    width: 100%;
    display: flex;
    background: white;
    box-shadow: 0 1px 3px var(--cds-color-neutral-stroke-primary-weak);

    @media (max-width: 608px) {
      padding-left: 0;
      padding-right: 0;
    }
  `,
  totalPagesNumber: css`
    margin-left: var(--cds-spacing-100);
  `,
  // OVERRIDES FOR CDS INPUT
  textField: css`
    .cds-text-field-root {
      margin-top: 0;
    }

    .cds-input-input {
      width: 15px !important;
      height: 10px !important;
    }

    label {
      display: none;
    }
  `,
};

export const Toolbar = () => {
  const PDF_ZOOM_STEP = 0.25;
  const PDF_MIN_ZOOM = 0.25;
  const PDF_MAX_ZOOM = 3;

  const {
    pdfViewerRef,
    totalPages,
    currentPageNumber,
    displayPageNumber,
    zoom,
    setZoom,
    setCurrentPageNumber,
    pageStart,
    pageEnd,
    assetId,
  } = usePdfViewerContext();

  const [pageInputVal, setPageInputVal] = React.useState<string>(displayPageNumber.toString());

  const handlePrevPage = () => {
    if (currentPageNumber > pageStart) {
      setCurrentPageNumber(currentPageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPageNumber < totalPages + pageStart - 1) {
      setCurrentPageNumber(currentPageNumber + 1);
    }
  };

  const handlePageNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = parseInt(event.target.value, 10);
    if (newPageNumber > totalPages) {
      setPageInputVal(totalPages.toString());
      setCurrentPageNumber(totalPages + pageStart - 1);
    } else if (newPageNumber <= 0) {
      setPageInputVal('1');
      setCurrentPageNumber(pageStart);
    } else if (isNaN(newPageNumber + pageStart - 1)) {
      setPageInputVal('');
    } else {
      setPageInputVal(newPageNumber.toString());
      setCurrentPageNumber(newPageNumber + pageStart - 1);
    }
  };

  const handleZoomIn = () => {
    setZoom(Math.min(PDF_MAX_ZOOM, zoom + PDF_ZOOM_STEP));
  };

  const handleZoomOut = () => {
    setZoom(Math.max(PDF_MIN_ZOOM, zoom - PDF_ZOOM_STEP));
  };

  const handleFullscreen = () => {
    const isFullscreen = !!document.fullscreenElement;

    if (!isFullscreen) {
      pdfViewerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleDownload = () => {
    const downloadUrl = new URL(`api/rest/v1/asset/download/pdf/${assetId}`, config.url.base);
    if (pageStart && pageEnd) {
      downloadUrl.searchParams.append('pageStart', pageStart.toString());
      downloadUrl.searchParams.append('pageEnd', pageEnd.toString());
    }
    // this endpoint returns the truncated file directly as the response
    window.open(downloadUrl);
  };

  useEffect(() => {
    setPageInputVal(displayPageNumber.toString());
  }, [displayPageNumber, totalPages]);

  return (
    <div css={styles.toolbar}>
      <Grid container alignItems="center">
        <Grid container alignItems="center" item xs spacing={4} wrap="nowrap">
          <Grid item>
            <Button
              css={styles.button}
              variant="ghost"
              onClick={handlePrevPage}
              aria-hidden="true"
              data-testid="prevPageButton"
            >
              <ChevronPreviousIcon color="interactive" />
            </Button>
          </Grid>
          <Grid item>
            <TextField
              css={styles.textField}
              type="number"
              inputProps={{ inputMode: 'numeric' }}
              value={pageInputVal}
              onChange={handlePageNumberChange}
              label={_t('Enter the page number you wish to jump to.')}
              aria-hidden="true"
            />
          </Grid>
          <Grid item data-testid="pageCount">
            <Typography2 css={styles.totalPagesNumber} component="span">
              {' '}
              / {totalPages}
            </Typography2>
          </Grid>
          <Grid item>
            <Button
              css={styles.button}
              variant="ghost"
              onClick={handleNextPage}
              aria-hidden="true"
              data-testid="nextPageButton"
            >
              <ChevronNextIcon color="interactive" />
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs alignItems="center" justifyContent="center" spacing={4} wrap="nowrap">
          <Grid item>
            <Button
              css={styles.button}
              variant="ghost"
              onClick={handleZoomOut}
              aria-hidden="true"
              data-testid="zoomOutButton"
            >
              <ZoomOutIcon color="interactive" />
            </Button>
          </Grid>
          <Grid item>
            <Hidden xsDown>
              <Typography2 component="span">{zoom * 100}%</Typography2>
            </Hidden>
          </Grid>
          <Grid item>
            <Button
              css={styles.button}
              variant="ghost"
              onClick={handleZoomIn}
              aria-hidden="true"
              data-testid="zoomInButton"
            >
              <ZoomInIcon color="interactive" />
            </Button>
          </Grid>
        </Grid>
        <Grid container item justifyContent="flex-end" alignItems="center" xs>
          <Grid item>
            <Button
              css={styles.button}
              variant="ghost"
              onClick={handleDownload}
              aria-label={_t('Download the provided PDF file')}
            >
              <DownloadIcon color="interactive" />
            </Button>
          </Grid>
          <Grid item>
            <Button
              css={styles.button}
              variant="ghost"
              onClick={handleFullscreen}
              aria-hidden="true"
              data-testid="fullscreenButton"
            >
              {document.fullscreenElement ? (
                <MinimizeScreenIcon color="interactive" />
              ) : (
                <FullscreenTwoIcon color="interactive" />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
