import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BaseElement, BlockElement, InlineElement, Text } from 'bundles/cml/shared/types/elementTypes';

export const isVoidOrTable = (node?: InlineElement | BlockElement): boolean => {
  return node?.isVoid || (node as BlockElement)?.type === BLOCK_TYPES.TABLE;
};

// eslint-disable-next-line no-prototype-builtins
export const isText = (el: Text | BaseElement): el is Text => el.hasOwnProperty('text');

// eslint-disable-next-line no-prototype-builtins
export const isElement = (el: Text | BaseElement): el is BaseElement => el.hasOwnProperty('type');
