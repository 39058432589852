import { useMemo } from 'react';

import type { CmlContent as NaptimeCmlContent, VariableData } from 'bundles/cml/legacy/types/Content';
import { getRenderableHtml, getRenderableHtmlMetadata, getValue } from 'bundles/cml/legacy/utils/CMLUtils';
import type { CmlContent } from 'bundles/cml/shared/types/CmlContent';
import { cmlToSlate } from 'bundles/cml/shared/utils/cmlToSlate';
import { getAssetData } from 'bundles/cml/viewer/utils/assetDataUtils';

export const useCMLToSlate = (
  initialCml: CmlContent | NaptimeCmlContent | undefined,
  variableData: VariableData | undefined,
  videoSubtitlesEnabled?: boolean
) => {
  const cml = getValue(initialCml);
  const renderableHtml = getRenderableHtml(initialCml);
  const renderableHtmlMetadata = getRenderableHtmlMetadata(initialCml);

  return useMemo(() => {
    const { assets, images } = getAssetData(renderableHtml, renderableHtmlMetadata, videoSubtitlesEnabled);
    return cmlToSlate(cml, images, assets, variableData);
  }, [cml, renderableHtml, variableData, renderableHtmlMetadata, videoSubtitlesEnabled]);
};
