import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import type { TextElement } from 'bundles/cml/shared/types/elementTypes';

const Paragraph: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const { variant } = element as TextElement;

  return (
    <p {...attributes} {...(variant && { 'data-text-variant': variant })}>
      {children}
    </p>
  );
};

export default Paragraph;
