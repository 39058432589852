import * as React from 'react';

import PropTypes from 'prop-types';

import FlexExperiments from 'bundles/epic/clients/Flex';
import TrackedButton from 'bundles/page/components/TrackedButton';

import _t from 'i18n!nls/alice';

import 'css!./__styles__/IsThisHelpfulCTA';

const { func } = PropTypes;

class IsThisHelpfulCTA extends React.Component {
  static propTypes = {
    onClose: func.isRequired,
  };

  constructor(props: $TSFixMe, context: $TSFixMe) {
    super(props, context);
    this.state = {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"buttonSwitched"' is not assigna... Remove this comment to see the full error message
      switchButton: FlexExperiments.get('buttonSwitched'),
    };
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { onClose } = this.props;
    return (
      <div className="rc-IsThisHelpfulCTA horizontal-box align-items-spacebetween body-1-text">
        {_t('Was this helpful?')}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'switchButton' does not exist on type 'Re... Remove this comment to see the full error message */}
        {this.state.switchButton ? (
          <div>
            <TrackedButton onClick={onClose} className="secondary" style={{ width: '50px' }} trackingName="helpful">
              {_t('Yes')}
            </TrackedButton>

            <TrackedButton
              onClick={onClose}
              trackingName="not_helpful"
              className="primary yes-button"
              style={{ width: '60px', marginLeft: '12px' }}
            >
              {_t('No')}
            </TrackedButton>
          </div>
        ) : (
          <div>
            <TrackedButton onClick={onClose} className="secondary" style={{ width: '50px' }} trackingName="not_helpful">
              {_t('No')}
            </TrackedButton>

            <TrackedButton
              onClick={onClose}
              trackingName="helpful"
              className="primary yes-button"
              style={{ width: '60px', marginLeft: '12px' }}
            >
              {_t('Yes')}
            </TrackedButton>
          </div>
        )}
      </div>
    );
  }
}

export default IsThisHelpfulCTA;
