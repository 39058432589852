import keysToConstants from 'js/lib/keysToConstants';

// NOTE: These types are used for out of course ALICE messages using the `contextType` parameter
// ALICE messages inside the course are retrieved using the `courseBranchId` parameter.
// See `AliceEvent.js` for more details.
const AliceContextTypes = keysToConstants(['LOGGED_IN_DASHBOARD', 'PROGRAM_HOME', 'XDP']);

export const { LOGGED_IN_DASHBOARD, PROGRAM_HOME, XDP } = AliceContextTypes;

export default AliceContextTypes;
