import * as React from 'react';

import ConsoleOutput from 'bundles/code-evaluator/components/ConsoleOutput';
import type EvaluationResponse from 'bundles/code-evaluator/models/EvaluationResponse';

import 'css!./__styles__/RuntimeError';

type Props = {
  evaluationResponse: EvaluationResponse;
};

const RuntimeError: React.FC<Props> = (props) => {
  const {
    evaluationResponse: { runtimeErrorResult },
  } = props;

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'evaluationResponse' does not exist on ty... Remove this comment to see the full error message
  const { errors } = runtimeErrorResult;

  return (
    <div className="rc-RuntimeError" role="alert">
      <ConsoleOutput>
        {errors.map((error: $TSFixMe) => {
          return <div key={error.message}>{error.message}</div>;
        })}
      </ConsoleOutput>
    </div>
  );
};

export default RuntimeError;
