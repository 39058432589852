import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import WidgetRenderer from 'bundles/cml/shared/components/widget/Widget';
import { useWidget } from 'bundles/cml/shared/hooks/useWidget';
import type { WidgetElement } from 'bundles/cml/shared/types/elementTypes';
import { useWidgetContext } from 'bundles/cml/viewer/context/widgetContext';

const Widget: React.FC<RenderElementProps> = (props) => {
  const { element } = props;
  const { widgetManager } = useWidgetContext();
  const widgetElement = element as WidgetElement;
  const { widgetPreview } = useWidget(widgetElement.id, widgetManager);

  return <WidgetRenderer widgetId={widgetElement.id} widget={widgetPreview} {...props} />;
};

export default Widget;
