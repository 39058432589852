import { useQuery } from '@apollo/client';

import getCourseIdByCourseSlugQuery from 'bundles/alice/components/queries/GetCourseIdByCourseSlugQuery.graphql';
import type {
  GetCourseIdByCourseSlugQuery,
  GetCourseIdByCourseSlugQueryVariables,
} from 'bundles/alice/components/queries/__generated__/GetCourseIdByCourseSlugQuery';

const useCourseData = (courseSlug?: string) => {
  const { data, loading, error } = useQuery<GetCourseIdByCourseSlugQuery, GetCourseIdByCourseSlugQueryVariables>(
    getCourseIdByCourseSlugQuery,
    {
      variables: { slug: `${courseSlug}` },
      skip: !courseSlug,
      context: { clientName: 'gatewayGql' },
    }
  );

  return {
    course: data?.Course?.queryBySlug,
    loading,
    error,
  };
};

export default useCourseData;
