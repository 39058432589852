import * as React from 'react';
import { useContext } from 'react';

// Every unified app page will update this context value to be 'true'.
// We will universally use this context in components that get pulled into unified apps
// to detect if we are in a unified app and to act accordingly.
const UnifiedAppCheckContext = React.createContext<boolean>(false);

export const useIsUnifiedAppCheck = () => {
  const isUnifiedAppCheck = useContext(UnifiedAppCheckContext);

  return isUnifiedAppCheck;
};

export default UnifiedAppCheckContext;
