import * as React from 'react';
import { useContext } from 'react';

import type { RenderElementProps } from 'slate-react';

import ImageRenderer from 'bundles/cml/shared/components/image/Image';
import { useAsset } from 'bundles/cml/shared/hooks/useAsset';
import type { ImageElement } from 'bundles/cml/shared/types/elementTypes';
import { AssetContext } from 'bundles/cml/viewer/context/assetContext';

const Image: React.FC<RenderElementProps> = (props) => {
  const { element } = props;
  const { assetManager } = useContext(AssetContext);

  const image = element as ImageElement;
  const { id = '', assetData } = image;

  const { assetData: data } = useAsset(id, assetManager, { shouldFetchAsset: !assetData });

  return <ImageRenderer {...props} assetData={assetData || data} />;
};

export default Image;
