/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  FloatingFocusManager,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { arrow, autoUpdate, flip, hide, limitShift, offset, shift } from '@floating-ui/react-dom';
import type { ReferenceType } from '@floating-ui/react-dom';

import logger from 'js/app/loggerSingleton';
import { isRightToLeft } from 'js/lib/language';

import { useFocusRing } from '@coursera/cds-core';

import Tooltip from 'bundles/common/components/Tooltip/Tooltip';
import TooltipArrow from 'bundles/common/components/Tooltip/TooltipArrow';
import { ARROW_OFFSET, TRANSITION_MS } from 'bundles/common/components/Tooltip/constants';
import type { Placement, Variant } from 'bundles/common/components/Tooltip/types';
import { getOnlyChild, mergeRefs, useDismissOnResize } from 'bundles/common/components/Tooltip/utils';
import generateUUID from 'bundles/common/utils/uuid';

import _t from 'i18n!nls/common';

export type Props = {
  message: React.ReactNode;

  id?: string;
  className?: string;
  placement?: Placement;
  delayShow?: number;
  delayHide?: number;
  variant?: Variant;
  trigger?: 'hover' | 'click' | 'controlled';
  show?: boolean;
  offset?: number;

  /**
   * Hides the tooltip when the reference element is hidden
   * see https://floating-ui.com/docs/hide#hide
   *
   * @default false
   */
  autoHide?: boolean;

  /**
   * A callback invoked when both the reference and floating elements are
   * mounted, and cleaned up when either is unmounted. This is useful for
   * setting up event listeners (e.g. pass `autoUpdate`).
   * see https://floating-ui.com/docs/usefloating#whileelementsmounted
   */
  whileElementsMounted?: (reference: ReferenceType, floating: HTMLElement, update: () => void) => () => void;
};

const FloatingTooltip: React.FC<Props> = ({
  placement = 'top',
  children,
  delayShow = 50,
  delayHide,
  message,
  className,
  id,
  variant,
  trigger = 'hover',
  show = true,
  offset: tooltipOffset = 0,
  whileElementsMounted = autoUpdate,
  autoHide = false,
}) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [tooltipId, setTooltipId] = useState(id);

  const arrowRef = useRef<HTMLDivElement>(null);

  const shouldOpen = trigger === 'controlled' ? show : open;
  useEffect(() => {
    const delayMS = !shouldOpen ? TRANSITION_MS : delayShow;
    const timeout = setTimeout(() => setVisible(shouldOpen), delayMS);

    return () => clearTimeout(timeout);
  }, [shouldOpen, delayShow]);

  useEffect(() => {
    setTooltipId(id || generateUUID());
  }, [id]);

  // defines methods for positioning the tooltip
  const middleware = [
    offset(tooltipOffset + ARROW_OFFSET), // sets pixel offset relative to reference element
    shift({ limiter: limitShift() }), // shifts the tooltip along the main axis to keep it in view
    flip(), // changes the placement of the tooltip to the opposite side to keep it in view
    arrow({ element: arrowRef, padding: 4 }), // provides positioning data to center the tooltip arrow
    autoHide ? hide({ strategy: 'referenceHidden' }) : null, // hides the tooltip if the reference element is hidden
  ];

  const hover = trigger === 'hover';
  const popover = trigger === 'click';

  const {
    context,
    x: floatingX,
    y: floatingY,
    refs: { setReference, setFloating },
    middlewareData: { arrow: arrowOffset, hide: { referenceHidden } = { referenceHidden: false } },
    placement: actualPlacement,
  } = useFloating({
    placement,
    strategy: 'fixed',
    open,
    onOpenChange: setOpen,
    middleware,
    whileElementsMounted,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { enabled: hover, delay: { close: delayHide } }),
    useFocus(context, { enabled: hover }),
    useClick(context, { enabled: popover }),
    useDismiss(context, { referencePress: hover, ancestorScroll: true }),
    useDismissOnResize(context, { enabled: hover || popover }),
    useRole(context, { role: hover ? 'tooltip' : 'dialog' }),
  ]);

  const { isFocusVisible, focusProps } = useFocusRing();

  const refElement = getOnlyChild(children);

  // @ts-expect-error
  const originalRef = refElement?.ref;
  const mergedRefs = useMemo(() => mergeRefs([setReference, originalRef]), [originalRef, setReference]);

  if (!refElement) {
    logger.error('Error: FloatingTooltip called without a valid child element');
    return null;
  }

  const showFloatingElement = show && visible;

  // attaches floating-ui props to the reference element without overriding
  // event listeners like click handlers, ref, etc...
  const refProps = getReferenceProps({
    ref: mergedRefs,
    'aria-describedby': showFloatingElement && hover ? tooltipId : undefined,
    onKeyDown(e) {
      if (e.key === 'Enter') setOpen(true);
    },
    ...refElement.props,
  });
  const floatingProps = getFloatingProps({ ref: setFloating, id: tooltipId, ...(popover ? focusProps : {}) });

  const horizontalDir = isRightToLeft(_t.getLocale()) ? 'right' : 'left';
  const tooltipPlacement = actualPlacement.split('-')[0] as Placement;
  const tooltip = (
    <Tooltip
      {...floatingProps}
      show={shouldOpen}
      className={className}
      variant={variant}
      focusVisible={isFocusVisible}
      css={{
        position: 'fixed',
        [horizontalDir]: floatingX ?? '',
        top: floatingY ?? '',
        visibility: referenceHidden ? 'hidden' : 'visible',
      }}
      arrow={
        <TooltipArrow
          ref={arrowRef}
          placement={tooltipPlacement}
          variant={variant}
          style={{
            top: arrowOffset?.y ?? '',
            [horizontalDir]: arrowOffset?.x ?? '',
          }}
        />
      }
    >
      {message}
    </Tooltip>
  );

  const floatingElement = popover ? (
    <FloatingFocusManager context={context} modal={false} order={['floating', 'content']}>
      {tooltip}
    </FloatingFocusManager>
  ) : (
    tooltip
  );

  return (
    <React.Fragment>
      {React.cloneElement(refElement, refProps)}
      <FloatingPortal>{showFloatingElement && floatingElement}</FloatingPortal>
    </React.Fragment>
  );
};

export default FloatingTooltip;
