/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import CMLCodeLanguageDisplay from 'bundles/cml/shared/components/code/CMLCodeLanguageDisplay';


const styles = {
  root: css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--cds-color-neutral-stroke-primary-weak);
  `,
  language: css`
    padding: var(--cds-spacing-100) var(--cds-spacing-200);
    border-right: 1px solid var(--cds-color-neutral-stroke-primary-weak);
  `,
  name: css`
    padding: var(--cds-spacing-100) var(--cds-spacing-200);
    overflow-wrap: anywhere;
  `,
};

type Props = {
  languageValue?: LanguageType;
  name?: string;
};

const CodeBlockHeader: React.FC<Props> = ({ languageValue, name }) => {
  if (!name) {
    return null;
  }

  return (
    <div css={styles.root}>
      {languageValue && (
        <div css={styles.language}>
          <CMLCodeLanguageDisplay languageValue={languageValue} />
        </div>
      )}
      <Typography2 css={styles.name} component="div" variant="bodySecondary">
        {name}
      </Typography2>
    </div>
  );
};

export default CodeBlockHeader;
