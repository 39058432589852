import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import type { ListItemElement } from 'bundles/cml/shared/types/elementTypes';

const ListItem: React.FC<RenderElementProps> = ({ element, children, attributes }) => {
  const listItem = element as ListItemElement;
  const ariaLevel = listItem['data-aria-level'] || listItem['aria-level'] || '';
  const ariaPosInSet = listItem['data-aria-posinset'] || listItem['aria-posinset'] || '';

  const listAttributes: React.HTMLAttributes<HTMLLIElement> = {};
  if (ariaLevel) {
    listAttributes['aria-level'] = parseInt(ariaLevel, 10);
  }

  if (ariaPosInSet) {
    listAttributes['aria-posinset'] = parseInt(ariaPosInSet, 10);
  }

  return (
    <li {...attributes} {...listAttributes}>
      {children}
    </li>
  );
};

export default ListItem;
