import type { PluginRpcMessage, RpcMessageHandlerFn } from 'bundles/widget-simulator/types/RpcMessages';

class Simulator {
  handlerMap: Record<string, RpcMessageHandlerFn> = {};

  addHandler(type: string, fn: RpcMessageHandlerFn) {
    this.handlerMap[type] = fn;
  }

  handleMessage(message: PluginRpcMessage) {
    const handler: RpcMessageHandlerFn | undefined = this.handlerMap[message.type];

    if (handler) {
      return handler(message);
    }

    // TODO: Surface this to the user.
    const error = new Error(`Unknown method: ${message.type}`);
    return Promise.reject(error);
  }
}

export default Simulator;
