import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

const List: React.FC<RenderElementProps> = ({ element, children, attributes }) => {
  const Tag = element.type === BLOCK_TYPES.BULLET_LIST ? 'ul' : 'ol';

  return <Tag {...attributes}>{children}</Tag>;
};

export default List;
