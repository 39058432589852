/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { ImageSizes } from 'bundles/cml/shared/types/coreTypes';

const styles = {
  caption: css`
    position: fixed;
    top: -999px;
    left: -999px;
    clip: rect(0, 0, 0, 0);
    border: none;
    text-transform: none;
  `,
  image: css`
    display: block;
  `,
};

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  description?: string;
  size?: ImageSizes;
  readonly?: boolean;
};

const ImageRenderer = React.forwardRef<HTMLImageElement, Props>((props, ref) => {
  const {
    src,
    alt,
    description,
    id = '',
    size = 'default',
    className,
    readonly,
    onClickCapture,
    onKeyDown,
    ...attributes
  } = props;
  const captionId = `caption-${id}`;

  return (
    <figure
      role="figure"
      contentEditable={false}
      tabIndex={0}
      onClickCapture={onClickCapture}
      onKeyDown={onKeyDown}
      ref={ref}
    >
      <img
        src={src}
        alt={alt}
        data-asset-id={id}
        aria-describedby={description ? captionId : undefined}
        className={`cml-image-${size} ${className}`}
        css={styles.image}
        {...attributes}
      />
      {description && (
        <figcaption id={captionId} css={styles.caption} aria-hidden="true">
          {description}
        </figcaption>
      )}
    </figure>
  );
});

export default ImageRenderer;
