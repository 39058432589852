/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { PdfViewerContextProvider } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import { PdfViewerUI } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerUI';
import type { PdfDocument, PdfJsLib } from 'bundles/cml/shared/components/asset/pdfViewer/types';
import inServerContext from 'bundles/ssr/util/inServerContext';

export type PdfViewerProps = {
  url: string;
  assetId: string;
  pageStart?: number;
  pageEnd?: number;
  onPdfLoad?: (doc: PdfDocument) => void;
};

export const PdfViewer: React.FC<PdfViewerProps> = ({ url, assetId, pageStart = 1, pageEnd, onPdfLoad }) => {
  const [reload, setReload] = React.useState(false);
  const [pdfjs, setPdfjs] = React.useState<PdfJsLib | undefined>(undefined);

  const pdfViewerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(window as any).pdfjsLib) {
      window.setTimeout(() => setReload(!reload), 100);
    } else {
      const pdfjsLib = (window as any).pdfjsLib;
      // a worker is required for the library to be able to load in a PDF document
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js';
      setPdfjs(pdfjsLib);
    }
  }, [url, reload]);

  if (inServerContext) {
    return null;
  }

  return (
    <PdfViewerContextProvider
      pdfJsLib={pdfjs}
      url={url}
      assetId={assetId}
      pdfViewerRef={pdfViewerRef}
      pageStart={pageStart}
      pageEnd={pageEnd}
      onPdfLoad={onPdfLoad}
    >
      {/* the pdfjs library is loaded from a CDN due to numerous issues with both our webpack and snowpack builds.
     Once we update our build to Vite, we can revisit this approach. */}
      <Helmet>
        <script defer src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js" />
      </Helmet>
      <PdfViewerUI />
    </PdfViewerContextProvider>
  );
};

export default PdfViewer;
