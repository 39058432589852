/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Button, Grid, Typography2 } from '@coursera/cds-core';
import { CloseIcon, MaximizeIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/widget';

export const headerHeight = 68;

export const styles = {
  truncated: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  modalHeader: css`
    padding: var(--cds-spacing-200) var(--cds-spacing-600);
    display: flex;
    flex-flow: wrap;
    gap: var(--cds-spacing-150) var(--cds-spacing-200);
    background: white;
    align-items: center;
  `,
};
type PropsToComponent = {
  widgetContentTitle?: string;
  onExpandToggle?: () => void;
  className?: string;
};

export const WidgetInPlaceModalHeader = ({ widgetContentTitle, onExpandToggle, className }: PropsToComponent) => {
  return (
    <Grid
      data-testid="widget-modal"
      css={styles.modalHeader}
      className={className}
      justifyContent={widgetContentTitle ? 'space-between' : 'flex-end'}
      container
    >
      <Typography2 component="h2" variant="subtitleLarge" css={styles.truncated}>
        {widgetContentTitle}
      </Typography2>

      <Button
        type="button"
        aria-label={_t('#{widgetContentTitle} window. Close window', { widgetContentTitle })}
        onClick={onExpandToggle}
        icon={<CloseIcon size="small" />}
        iconPosition="after"
        variant="ghost"
        size="small"
        className="read-only-wrapper-allow-interaction"
      >
        {_t('Close')}
      </Button>
    </Grid>
  );
};

type PropsToExpandButton = {
  label: string;
  onClick: React.MouseEventHandler;
};
export const WidgetExpandButton = ({ label, onClick }: PropsToExpandButton) => {
  return (
    <Button
      onClick={onClick}
      aria-label={label}
      variant="secondary"
      iconPosition="before"
      size="medium"
      icon={<MaximizeIcon />}
    >
      {_t('Expand')}
    </Button>
  );
};
