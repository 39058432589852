import logger from 'js/app/loggerSingleton';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import AssetManagerJS from 'js/lib/AssetManager';

import type { AssetManager as AssetManagerType } from 'bundles/cml/shared/types/assetManager';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';

const getAsset = (id: string): Asset | undefined => {
  return AssetManagerJS.ASSET_CACHE[id];
};

const fetchAsset = async (id: string): Promise<Asset | undefined> => {
  const assetManager = new AssetManagerJS();
  try {
    const assets = await assetManager.getAssetMap([id]);
    return assets[id];
  } catch (e) {
    AssetManagerJS.GET_FAIL_COUNT -= 1;
    logger.error(`Error: CMLEditor failed to fetch asset '${id}'`, e);
    return Promise.reject(e);
  }
};

const addAssetListener = (id: string, callback: (asset: Asset) => void) => {
  AssetManagerJS.addAssetUpdateListener(id, callback);
};

const removeAssetListener = (id: string, callback: (asset: Asset) => void) => {
  AssetManagerJS.removeAssetUpdateListener(id, callback);
};

const updateAssetMap = (asset: Asset) => {
  AssetManagerJS.updateAssetMap(asset);
};

const AssetManager: AssetManagerType = {
  fetchAsset,
  addAssetListener,
  removeAssetListener,
  updateAssetMap,
  getAsset,
};

export default AssetManager;
