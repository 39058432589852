export const AssetTypeNames = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
  GENERIC: 'generic',
} as const;
export type AssetTypeName = (typeof AssetTypeNames)[keyof typeof AssetTypeNames];

export const isAssetTypeName = (maybeAssetTypeName: string | null): maybeAssetTypeName is AssetTypeName =>
  !!maybeAssetTypeName && (Object.values(AssetTypeNames) as string[]).includes(maybeAssetTypeName);

export type AssetTag = {
  name: string;
  value: string;
};

export type Asset = {
  typeName: AssetTypeName;
  createdAt?: number;
  filename: string;
  id: string;
  name: string;
  tags: Array<AssetTag>;
  fileExtension: string;
  url: {
    expires: number;
    url: string;
  };
  isDescriptionAutoGenerated?: boolean;
  autoGenDescriptionConfidenceLevel?: number;
  videoSourceUrls?: unknown;
  videoThumbnailUrls?: unknown;
};

export type ContentAsset = Omit<Asset, 'url'> & {
  url: string;
  description: string;
  longDescription: string;
};

export const VideoResolutions = {
  '240': '240p',
  '360': '360p',
  '540': '540p',
  '720': '720p',
} as const;
export type VideoResolution = (typeof VideoResolutions)[keyof typeof VideoResolutions];

export type VideoThumbnailUrl = {
  expires: number;
  url: string;
};

export type VideoThumbnailUrls = Partial<{
  [resolution in VideoResolution]: VideoThumbnailUrl[];
}>;

export type VideoAsset = Asset & {
  typeName: typeof AssetTypeNames.VIDEO;
  videoThumbnailUrls: VideoThumbnailUrls;
};

export type AssetConfig = {
  fetchUrls: boolean;
  courseId: string;
  assetType?: AssetTypeName;
  keyword?: string;
  startIndex?: number;
  hideAssets?: boolean;
  pageSize?: number;
};

export type SelectOptions = {
  asset: Asset;
  allowMultiple?: boolean;
};

export type AssetMap = {
  [key: string]: Asset;
};

export type AssetContext = { courseId: string };

export type AcceptedAssetType = {
  name: string;
  types: Array<string>;
  extensions?: Array<string>;
  label?: string;
};

export type AcceptedAssetTypeMap = {
  [key: string]: AcceptedAssetType;
};
