import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import CodeRenderer from 'bundles/cml/shared/components/code/Code';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';
import { useCodeBlockContext } from 'bundles/cml/viewer/context/codeBlockContext';

import _t from 'i18n!nls/cml';

const Code: React.FC<RenderElementProps> = (props) => {
  const { element } = props;
  const code = element as CodeElement;
  const { codeBlockIndices } = useCodeBlockContext();

  const codeBlockLabel = _t('code block #{label}', {
    label: code.name || codeBlockIndices?.[code.id],
  });

  return <CodeRenderer {...props} ariaLabel={codeBlockLabel} displayName={code.name} />;
};

export default Code;
