/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Document } from 'bundles/cml/shared/components/asset/pdfViewer/Document';
import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import { Toolbar } from 'bundles/cml/shared/components/asset/pdfViewer/Toolbar';

const styles = {
  pdfViewer: css`
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
  `,
};

export const PdfViewerUI = () => {
  const { pdfViewerRef } = usePdfViewerContext();

  return (
    <div ref={pdfViewerRef} css={styles.pdfViewer}>
      <Toolbar />
      <Document />
    </div>
  );
};
