/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { LoadingSection } from '@coursera/cds-core';

import { Page } from 'bundles/cml/shared/components/asset/pdfViewer/Page';
import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import type { PdfDocument } from 'bundles/cml/shared/components/asset/pdfViewer/types';

import _t from 'i18n!nls/cml';

const styles = {
  document: css`
    background: var(--cds-color-neutral-background-primary-weak);
    display: flex;
    overflow: auto;
    position: relative;
    height: 100%;
  `,
  loader: css`
    position: absolute;
    top: 0;
    background: transparent;
  `,
};

export const Document: React.FC = () => {
  const { pdfJsLib, currentPage, url, setTotalPages, setPdf, onPdfLoad } = usePdfViewerContext();

  React.useEffect(() => {
    pdfJsLib?.getDocument(url).promise.then((doc: PdfDocument) => {
      setPdf(doc);
      onPdfLoad?.(doc);

      setTotalPages(doc.numPages);
    });
  }, [url, setPdf, setTotalPages, pdfJsLib, onPdfLoad]);

  return (
    <div css={styles.document}>
      {!currentPage && <LoadingSection css={styles.loader} aria-label={_t('Loading PDF')} />}
      <Page />
    </div>
  );
};
