const TestUserIdSet: Set<number> = new Set([13744922]);

export type WriteAccessState = 'ACCESS_GRANTED' | 'INSUFFICIENT_PERMISSIONS' | 'ATOM_VERSION_NOT_AT_LATEST';

export const WriteAccessStates: {
  ACCESS_GRANTED: WriteAccessState;
  INSUFFICIENT_PERMISSIONS: WriteAccessState;
  ATOM_VERSION_NOT_AT_LATEST: WriteAccessState;
} = {
  ACCESS_GRANTED: 'ACCESS_GRANTED',
  INSUFFICIENT_PERMISSIONS: 'INSUFFICIENT_PERMISSIONS',
  ATOM_VERSION_NOT_AT_LATEST: 'ATOM_VERSION_NOT_AT_LATEST',
};

const exported = {
  ItemStates: {
    PUBLISHED: 'PUBLISHED',
    READ_ONLY: 'READ_ONLY',
    ERROR: 'ERROR',
    TRANSIENT_ERROR: 'TRANSIENT_ERROR',
  },

  WriteAccessStates,

  TestUserIdSet,
};

export default exported;
export { TestUserIdSet };

export const { ItemStates } = exported;
