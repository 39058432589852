import { createContext, useContext } from 'react';

import type { AssetManager } from 'bundles/cml/shared/types/assetManager';

export type AssetContextType = {
  assetManager: AssetManager;
};

export const AssetContext = createContext<AssetContextType>({
  assetManager: {
    getAsset: () => undefined,
    fetchAsset: () => Promise.resolve(undefined),
    addAssetListener: () => undefined,
    removeAssetListener: () => undefined,
    updateAssetMap: () => undefined,
  },
});

export const useAssetContext = () => useContext(AssetContext);
