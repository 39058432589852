/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';

import { SimpleLinkService } from 'bundles/cml/shared/components/asset/pdfViewer/PdfLinkService';
import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';

const styles = {
  annotationContainer: css`
    height: 100%;
    width: 100%;
    position: absolute;
    margin-top: var(--cds-spacing-200);
    margin-bottom: var(--cds-spacing-200);
  `,
  annotationLayer: css`
    top: 0;
    left: 0;
    pointer-events: none;

    /* These elements and classes are appended by pdfjs to the annotation layer
    if we need to add additional annotation functionality, see here for additional styles we'll need to add:
    https://github.com/mozilla/pdf.js/blob/master/web/annotation_editor_layer_builder.css */
    section {
      position: absolute;
      text-align: initial;
      pointer-events: auto;
      box-sizing: border-box;
      transform-origin: 0 0;

      :is(.linkAnnotation) > a {
        position: absolute;
        font-size: 1em;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      :is(.linkAnnotation) > a:hover {
        opacity: 0.2;
        background-color: var(--cds-color-feedback-informative);
      }
    }
  `,
};

export const AnnotationLayer = () => {
  const annotationLayerDiv = useRef<HTMLDivElement>(null);
  const annotationContainerDiv = useRef<HTMLDivElement>(null);
  const { currentPage, pdfJsLib, zoom } = usePdfViewerContext();

  useEffect(() => {
    if (!annotationLayerDiv.current || !pdfJsLib) {
      return;
    }

    currentPage?.getAnnotations().then((annotations) => {
      if (annotationLayerDiv.current) {
        // we need to manually remove any existing annotations
        annotationLayerDiv.current.innerHTML = '';
      }
      const viewport = currentPage.getViewport({ scale: zoom });
      if (annotationContainerDiv.current) {
        annotationContainerDiv.current.style.width = `${Math.floor(viewport.width)}px`;
        annotationContainerDiv.current.style.height = `${Math.floor(viewport.height)}px`;
      }

      const annotationLayer = new pdfJsLib.AnnotationLayer({
        div: annotationLayerDiv.current,
        accessibilityManager: null,
        annotationCanvasMap: null,
        annotationEditorUIManager: null,
        annotations,
        page: currentPage,
        viewport: viewport.clone({ dontFlip: true }),
      });

      annotationLayer.render({
        annotations,
        imageResourcesPath: '',
        renderForms: false,
        linkService: new SimpleLinkService(),
        downloadManager: null,
        annotationStorage: null,
        enableScripting: false,
        hasJSActions: false,
        fieldObjects: null,
      });
    });
  }, [currentPage, pdfJsLib, zoom]);

  return (
    <div ref={annotationContainerDiv} css={styles.annotationContainer}>
      <div ref={annotationLayerDiv} css={styles.annotationLayer} data-testid="annotationLayer" />
    </div>
  );
};
