/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';

import { AnnotationLayer } from 'bundles/cml/shared/components/asset/pdfViewer/AnnotationLayer';
import { usePdfViewerContext } from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewerContext';
import type { PdfPage } from 'bundles/cml/shared/components/asset/pdfViewer/types';
import { pdfJsRenderPage } from 'bundles/cml/shared/components/asset/pdfViewer/utils';

const styles = {
  pageContainer: css`
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: fit-content;
  `,
  page: css`
    margin-top: var(--cds-spacing-200);
    margin-bottom: var(--cds-spacing-200);
  `,
};

export const Page: React.FC = () => {
  const pageNumPending = useRef<number | null>(null);
  const pageRendering = useRef(false);

  const { pdfViewerRef, pdf, zoom, currentPage, currentPageNumber, setCurrentPage, setZoom } = usePdfViewerContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const preFullscreenZoom = useRef(zoom);

  const onFullscreenChange = useCallback(() => {
    if (!currentPage) return;

    const fullscreenStatus = !!document.fullscreenElement;
    if (fullscreenStatus) {
      const desiredHeight = window.innerHeight;
      const defaultViewport = currentPage.getViewport({ scale: 1 });
      // round to the nearest .25 increment
      const fullscreenScale = Math.round((desiredHeight / Math.min(desiredHeight, defaultViewport.height)) * 4) / 4;
      preFullscreenZoom.current = zoom;
      setZoom(fullscreenScale);
    } else {
      setZoom(preFullscreenZoom.current);
    }
  }, [currentPage, setZoom, zoom]);

  const getNextPage = useCallback(
    (num: number) => {
      pdf?.getPage(num).then((page: PdfPage) => setCurrentPage(page));
    },
    [pdf, setCurrentPage]
  );

  // load the page using pdfjs APIs
  useEffect(() => {
    if (!pdf) {
      return;
    }
    if (pageRendering.current) {
      pageNumPending.current = currentPageNumber;
    } else {
      pageRendering.current = true;
      getNextPage(currentPageNumber);
    }
  }, [currentPageNumber, pdf, getNextPage, setCurrentPage]);

  // listen for fullscreen change event
  useEffect(() => {
    const pdfViewer = pdfViewerRef.current;
    pdfViewer?.addEventListener('fullscreenchange', onFullscreenChange);

    return () => pdfViewer?.removeEventListener('fullscreenchange', onFullscreenChange);
  }, [onFullscreenChange, pdfViewerRef]);

  // (re)render the pdf page whenever the zoom or page changes
  useEffect(() => {
    const canvas = canvasRef.current;

    pdfJsRenderPage({ canvas, currentPage, zoom })
      .then(() => {
        if (pageNumPending.current !== null) {
          getNextPage(pageNumPending.current);
          pageNumPending.current = null;
        }
        pageRendering.current = false;
      })
      .catch(() => null);
  }, [currentPage, pageNumPending, getNextPage, zoom]);

  return (
    <div css={styles.pageContainer}>
      <canvas ref={canvasRef} css={[styles.page]} data-testid="canvasElement" />
      <AnnotationLayer />
    </div>
  );
};
