import type Monaco from 'monaco-editor';

export const getMonacoEditorInputElement = (
  editor: Monaco.editor.IStandaloneCodeEditor | null
): HTMLInputElement | null => {
  // TODO(wbowers): Is there a better way to get the editor's input element?
  return editor?.getDomNode()?.querySelector('.inputarea') || null;
};

export const isMonacoEditorFocused = (editor: Monaco.editor.IStandaloneCodeEditor | null): boolean => {
  return getMonacoEditorInputElement(editor) === document.activeElement;
};

export const defineCustomTheme = (themeName: string, monaco: typeof Monaco) => {
  monaco.editor.defineTheme(themeName, {
    base: 'vs', // can also be vs-dark or hc-black
    inherit: true, // can also be false to completely replace the builtin rules
    rules: [
      // custom colors to fix contrast issues
      { token: 'string.sql', foreground: 'EB0000' },
      { token: 'operator.sql', foreground: '637483' },
      { token: 'predefined.sql', foreground: 'D100D1' },
    ],
    colors: {},
  });
};
