import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

import type { ActionContext } from 'fluxible';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connectToStores from 'vendor/cnpm/fluxible.v0-4/addons/connectToStores';

import Retracked from 'js/app/retracked';
import connectToRouter from 'js/lib/connectToRouter';
import deferToClientSideRender from 'js/lib/deferToClientSideRender';
import isMobileApp from 'js/lib/isMobileApp';
import waitFor from 'js/lib/waitFor';

import { dismissAliceNotification } from 'bundles/alice/actions/AliceActions';
import AliceMessage from 'bundles/alice/components/AliceMessage';
import useCourseData from 'bundles/alice/components/hooks/useCourseData';
import type AliceMessageData from 'bundles/alice/models/AliceMessageData';
import aliceExperiments from 'bundles/epic/clients/alice';

import 'css!./__styles__/Alice';

type Props = {
  message: AliceMessageData;
  courseSlug?: string;
};

const Alice: React.FC<Props> = (props, context?: ActionContext) => {
  const { executeAction } = context || {};
  const { message, courseSlug } = props;

  const { course, loading, error } = useCourseData(courseSlug);

  const handleClose = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'AliceMessage... Remove this comment to see the full error message
    const id = message?.id;
    if (id) {
      executeAction?.(dismissAliceNotification, { id });
    }
  };

  const isAliceEnabled = aliceExperiments.get('aliceEnabled', { course_id: course?.id || 'NO_ID' });

  if (loading || error || !isAliceEnabled) {
    return null;
  }

  return (
    <div className="rc-Alice">
      <CSSTransitionGroup
        transitionEnter={true}
        transitionLeave={true}
        transitionAppear={true}
        transitionName="alice-fade"
        transitionEnterTimeout={800}
        transitionLeaveTimeout={600}
        transitionAppearTimeout={800}
      >
        {!!message && <AliceMessage message={message} onClose={handleClose} />}
      </CSSTransitionGroup>
    </div>
  );
};

Alice.contextTypes = {
  executeAction: PropTypes.func.isRequired,
};

export default compose<Props, {}>(
  waitFor(() => !isMobileApp.get()),
  deferToClientSideRender(),
  connectToRouter<{ courseSlug?: string }>((router) => ({
    courseSlug: router.params.courseSlug,
  })),
  connectToStores(['AliceStore'], ({ AliceStore }) => ({
    message: AliceStore.getPublishedMessage(),
  })),
  Retracked.createTrackedContainer((props: Props) => ({
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'AliceMessage... Remove this comment to see the full error message
    id: props.message && props.message.id,
    namespace: { page: 'alice' },
  }))
)(Alice);
