import * as React from 'react';

import CodeEvaluatorProgress from 'bundles/code-evaluator/components/CodeEvaluatorProgress';
import RequestError from 'bundles/code-evaluator/components/RequestError';
import RuntimeError from 'bundles/code-evaluator/components/RuntimeError';
import SuccessOutput from 'bundles/code-evaluator/components/SuccessOutput';
import SystemError from 'bundles/code-evaluator/components/SystemError';
import useLoadEvaluatorSummary from 'bundles/code-evaluator/hooks/useLoadEvaluatorSummary';
import type Evaluation from 'bundles/code-evaluator/models/Evaluation';
import type EvaluationResponse from 'bundles/code-evaluator/models/EvaluationResponse';
import type EvaluatorSummary from 'bundles/code-evaluator/models/EvaluatorSummary';

import 'css!./__styles__/CodeEvaluatorOutput';

type Props = {
  evaluation: Evaluation;
  evaluatorId: string;
  evaluatorSummary?: EvaluatorSummary;
  onCancel: () => void;
};

type EvaluatorResponseProps = {
  evaluationResponse: EvaluationResponse;
  hasSuccessResult: boolean;
  hasRuntimeError: boolean;
  hasSystemError: boolean;
};

const EvaluatorResponse = ({
  evaluationResponse,
  hasSuccessResult,
  hasRuntimeError,
  hasSystemError,
}: EvaluatorResponseProps) => {
  return (
    <>
      {hasSuccessResult && <SuccessOutput evaluationResponse={evaluationResponse} />}
      {hasRuntimeError && <RuntimeError evaluationResponse={evaluationResponse} />}
      {hasSystemError && <SystemError evaluationResponse={evaluationResponse} />}
    </>
  );
};

const CodeEvaluatorOutput = (props: Props) => {
  const { evaluation, onCancel, evaluatorId, evaluatorSummary: receivedEvaluatorSummary } = props;
  const evaluatorSummary = useLoadEvaluatorSummary(evaluatorId, receivedEvaluatorSummary);

  const { active, response, hasRequestError, hasSuccessResult, hasRuntimeError, hasSystemError } = evaluation;

  if (!active && !hasRequestError && !response) {
    return null;
  }

  return (
    <div className="rc-CodeEvaluatorOutput">
      {active && <CodeEvaluatorProgress evaluatorSummary={evaluatorSummary} onCancel={onCancel} />}

      {hasRequestError && <RequestError />}

      {response && (
        <EvaluatorResponse
          evaluationResponse={response}
          hasSuccessResult={hasSuccessResult}
          hasRuntimeError={hasRuntimeError}
          hasSystemError={hasSystemError}
        />
      )}
    </div>
  );
};

export default CodeEvaluatorOutput;
