/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import type { RenderElementProps } from 'slate-react';

import { Skeleton } from '@coursera/cds-core';

import WidgetPreviewFrame from 'bundles/author-widget/components/preview/WidgetPreviewFrame';
import WidgetButtonContainer from 'bundles/cml/shared/components/widget/WidgetButtonContainer';
import type { WidgetSessionPreview } from 'bundles/cml/shared/types/widgetManager';

const DEFAULT_HEIGHT = 704; // This just happens to be the height of the widget preview container.

const styles = {
  widget: css`
    .rc-WidgetPreviewFrame {
      margin: 0;
    }
  `,
};

type Props = RenderElementProps & {
  widget?: WidgetSessionPreview;
  widgetId?: string;
  className?: string;
  buttonMenuRef?: React.RefObject<HTMLButtonElement>;
  onClick?: () => void;
};

const Widget = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { widget, widgetId, attributes, children, className, buttonMenuRef, onClick } = props;

  const renderButtonContainer = useCallback(
    (onExpand: () => void) => <WidgetButtonContainer ref={buttonMenuRef} onClick={onClick} onExpand={onExpand} />,
    [buttonMenuRef, onClick]
  );

  return (
    <div {...attributes} data-id="cml-widget" className={className}>
      {!widget ? (
        <div contentEditable={false}>
          <Skeleton height={DEFAULT_HEIGHT} variant="rect" />
          {children}
        </div>
      ) : (
        <div ref={ref} css={styles.widget}>
          {children}
          <WidgetPreviewFrame
            sessionId={widgetId}
            session={widget}
            showPopupButton={true}
            renderCustomContainer={renderButtonContainer}
          />
        </div>
      )}
    </div>
  );
});

export default Widget;
