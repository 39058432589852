import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

type Props = RenderElementProps & {
  header?: React.ReactNode;
  rows: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

const Table = React.forwardRef<HTMLTableElement, Props>(({ children, attributes, className, header, rows }, ref) => {
  return (
    <div {...attributes} className={className}>
      <table ref={ref}>
        {header && <thead>{header}</thead>}
        <tbody>{rows}</tbody>
      </table>
      {children}
    </div>
  );
});

export default Table;
