import * as React from 'react';

import PropTypes from 'prop-types';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { TrackedA } from 'bundles/page/components/TrackedLink2';

import _t from 'i18n!nls/alice';

const { string, func, shape } = PropTypes;

class TwoButtonCTA extends React.Component {
  static propTypes = {
    onClose: func.isRequired,
    action: shape({
      title: string,
      url: string,
    }),
  };

  renderCallToAction() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
      onClose,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message
      action: { title, url },
    } = this.props;

    return (
      <TrackedA
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        data={{ url }}
        onClick={onClose}
        trackingName="action"
        className="link-button primary"
        style={{ width: '48%' }}
      >
        {title}
      </TrackedA>
    );
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { onClose } = this.props;

    return (
      <div className="rc-TwoButtonCTA horizontal-box align-items-spacebetween">
        <TrackedButton onClick={onClose} className="secondary" style={{ width: '48%' }} trackingName="dismiss_action">
          {_t('Not now')}
        </TrackedButton>
        {this.renderCallToAction()}
      </div>
    );
  }
}

export default TwoButtonCTA;
