import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import ImagePlaceholder from 'bundles/cml/shared/components/image/ImagePlaceholder';
import ImageRenderer from 'bundles/cml/shared/components/image/ImageRenderer';
import type { ImageAssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { ImageElement } from 'bundles/cml/shared/types/elementTypes';

type Props = RenderElementProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
    className?: string;
    assetData?: ImageAssetData;
    readonly?: boolean;
  };

const Image = React.forwardRef<HTMLImageElement, Props>((props, ref) => {
  const { element, attributes, children, onClick, onKeyDown, className, assetData, readonly, ...imgAttributes } = props;

  const image = element as ImageElement;
  const { id, src, size = 'default' } = image;

  const url = assetData?.url || src;
  const renderPlaceholder = !url;

  return (
    <div {...attributes}>
      {children}
      {renderPlaceholder ? (
        <ImagePlaceholder size={size} />
      ) : (
        <ImageRenderer
          ref={ref}
          id={id}
          src={url}
          alt={assetData?.alt}
          size={size}
          description={assetData?.description}
          className={className}
          readonly={readonly}
          {...imgAttributes}
          onClickCapture={onClick}
          onKeyDown={onKeyDown}
        />
      )}
    </div>
  );
});

export default Image;
