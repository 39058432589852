import * as React from 'react';

import PropTypes from 'prop-types';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import IsThisHelpfulCTA from 'bundles/alice/components/IsThisHelpfulCTA';
import TipIcon from 'bundles/alice/components/TipIcon';
import TwoButtonCTA from 'bundles/alice/components/TwoButtonCTA';
import AliceMessageData from 'bundles/alice/models/AliceMessageData';
import { CML } from 'bundles/cml';
import TrackedDiv from 'bundles/page/components/TrackedDiv';

import _t from 'i18n!nls/alice';

import 'css!./__styles__/AliceMessage';

class AliceMessage extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    message: PropTypes.instanceOf(AliceMessageData),
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { onClose, message } = this.props;

    return (
      <div className="rc-AliceMessage">
        <TrackedDiv
          aria-label={_t('Alice')}
          className="alice-container"
          trackingName="alice_message"
          trackClicks={false}
          requireFullyVisible={false}
          withVisibilityTracking={true}
        >
          <TipIcon className="alice-icon" />

          <div className="alice-modal-body">
            <div className="alice-modal-header">
              <h2 className="headline-1-text">
                <FormattedHTMLMessage message={message.title} />
              </h2>
            </div>

            <CML
              className="alice-modal-main"
              cml={message.message}
              shouldRenderMath={false}
              shouldRenderCode={false}
              shouldRenderAssets={false}
            />

            {message.dataSourceDescription && (
              <CML
                className="data-source-desc color-secondary-text"
                cml={message.dataSourceDescription}
                shouldRenderMath={false}
                shouldRenderCode={false}
                shouldRenderAssets={false}
              />
            )}

            <div className="alice-buttons">
              {message.action && message.action.url !== '' ? (
                <TwoButtonCTA onClose={onClose} action={message.action} />
              ) : (
                <IsThisHelpfulCTA onClose={onClose} />
              )}
            </div>
          </div>
        </TrackedDiv>
      </div>
    );
  }
}

export default AliceMessage;
