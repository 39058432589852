import URI from 'jsuri';
import Q from 'q';

import API from 'js/lib/api';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import type { AuthoringWidgetSessionProvidersV1 } from 'bundles/naptimejs/resources/__generated__/AuthoringWidgetSessionProvidersV1';

const authoringWidgetSessionProviderAPI = API('/api/authoringWidgetSessionProviders.v1/', { type: 'rest' });

// naptime types this as any, but this field seems to be required.
export interface GraderConfiguration {
  passingValue: string;
}

export const createWidgetSessionProvider = (
  widgetId: string,
  iframeTitle: string,
  courseId: string,
  configuration: object,
  graderConfiguration?: GraderConfiguration
) => {
  const owner = tupleToStringKey(['COURSE', courseId]);
  const uri = new URI().addQueryParam('fields', 'draft,isPublished,metadata,owners');

  const data: {
    widgetId: string;
    iframeTitle: string;
    owner: string;
    configuration: $TSFixMe;
    graderConfiguration?: GraderConfiguration;
    courseId: string;
  } = {
    widgetId,
    iframeTitle,
    owner,
    configuration,
    courseId,
  };

  if (graderConfiguration) {
    data.graderConfiguration = graderConfiguration;
  }

  return Q(authoringWidgetSessionProviderAPI.post(uri.toString(), { data }));
};

// added to provide courseId argument for permission lookup.
// /api/authoringWidgetSessionProviders.v1?q=getWithCourseId&widgetSessionProviderId=:id&courseId=:courseId
export const getWidgetSessionProviderByIdAndCourseId = ({ id, courseId }: { id: string; courseId: string }) => {
  const widgetSessionProviderId = id;
  const uri = new URI()
    .addQueryParam('q', 'getWithCourseId')
    .addQueryParam('widgetSessionProviderId', widgetSessionProviderId)
    .addQueryParam('courseId', courseId)
    .addQueryParam(
      'fields',
      'draft,isPublished,metadata,owners,id,authoringWidgetSummaries.v1(name,description,configurationExamples)'
    )
    .addQueryParam('includes', 'widgetSummary');

  return Q(authoringWidgetSessionProviderAPI.get(uri.toString()));
};

export const updateWidgetSessionProvider = (
  widgetSessionProvider: AuthoringWidgetSessionProvidersV1,
  iframeTitle: string,
  widgetSessionProviderId: string,
  configuration: $TSFixMe,
  courseId: string,
  graderConfiguration?: GraderConfiguration
) => {
  const uri = new URI(widgetSessionProviderId).addQueryParam('fields', 'draft,isPublished,metadata,owners,id');
  const { metadata } = widgetSessionProvider;

  const data: {
    iframeTitle?: string;
    metadata: object;
    configuration: $TSFixMe;
    courseId: string;
    graderConfiguration?: GraderConfiguration;
  } = {
    configuration,
    metadata,
    courseId,
  };
  if (iframeTitle) {
    data.iframeTitle = iframeTitle;
  }

  if (graderConfiguration) {
    data.graderConfiguration = graderConfiguration;
  }

  return Q(authoringWidgetSessionProviderAPI.patch(uri.toString(), { data }));
};

export const publishWidgetSessionProvider = ({ id, courseId }: { id: string; courseId: string }) => {
  const widgetSessionProviderId = id;
  const uri = new URI().addQueryParam('action', 'publish').addQueryParam('id', widgetSessionProviderId);

  const data = { courseId };
  return Q(authoringWidgetSessionProviderAPI.post(uri.toString(), { data }));
};

export const previewWidgetSessionProvider = (
  widgetSessionProviderId: string,
  courseId: string,
  itemId: string,
  branchId?: string
) => {
  const uri = new URI().addQueryParam('action', 'preview').addQueryParam('id', widgetSessionProviderId);

  const data = {
    courseId,
    branchId,
    itemId,
  };

  return Q(authoringWidgetSessionProviderAPI.post(uri.toString(), { data }));
};

const AuthoringWidgetSessionApiUtils = {
  createWidgetSessionProvider,
  updateWidgetSessionProvider,
};

export default AuthoringWidgetSessionApiUtils;
