/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import CodeBlockHeader from 'bundles/cml/shared/components/code/CodeBlockHeader';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';
import type { Props as CodeBlockProps } from 'bundles/code-evaluator/components/CodeBlockV2';

const styles = {
  root: css`
    border: 1px solid var(--cds-color-neutral-stroke-primary-weak);

    .rc-CodeBlock {
      border: 1px solid var(--cds-color-neutral-stroke-primary-weak);
      min-height: 40px;
    }
  `,
};

type Props = RenderElementProps & Partial<CodeBlockProps> & { className?: string; displayName?: string };

const Code = React.forwardRef<CodeBlockV2, Props>(
  ({ element, children, attributes, className, displayName, ...codeBlockProps }, ref) => {
    const code = element as CodeElement;

    return (
      <div {...attributes} css={styles.root} className={className}>
        <div contentEditable={false}>
          <CodeBlockHeader languageValue={codeBlockProps.codeLanguage} name={displayName} />
          <CodeBlockV2
            ref={ref}
            codeLanguage={code.language}
            expression={code.codeText}
            evaluatorId={code.evaluatorId}
            minLines={6}
            {...codeBlockProps}
          />
          {children}
        </div>
      </div>
    );
  }
);

export default Code;
