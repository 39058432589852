/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import AssetV2, { isAssetPropsVideo } from 'bundles/cml/legacy/components/AssetV2';
import type { AssetProps } from 'bundles/cml/legacy/components/AssetV2';
import { isPDfEmbedEnabled, isSupportedMediaAsset } from 'bundles/cml/legacy/utils/AssetUtils';
import { isVideoAssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  link: css`
    /* reset link styles so the AssetV2 styles are preferred */
    color: initial !important;
    text-decoration: initial !important;
  `,
};

type Props = RenderElementProps & {
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  readonly?: boolean;
  assetData?: AssetData;
};

const renderAssetV2 = (id: string, assetData: AssetData, assetElement: AssetElement) => {
  const assetV2Props: AssetProps = {
    id,
    assetData,
    assetElement,
  };

  if (isAssetPropsVideo(assetV2Props) && isVideoAssetData(assetData)) {
    return (
      <AssetV2 {...assetV2Props} videoResolutions={assetData.resolutions} subtitleTracks={assetData.subtitleTracks} />
    );
  } else {
    return <AssetV2 {...assetV2Props} />;
  }
};

const Asset = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { element, attributes, children, assetData, onClick, className, readonly = false } = props;

  const assetElement = element as AssetElement;

  const maybeRenderedAssetV2 = assetData && renderAssetV2(assetElement.id, assetData, assetElement);

  const renderAsset = () => {
    if (isPDfEmbedEnabled(assetElement) || isSupportedMediaAsset(assetData) || !readonly) {
      return maybeRenderedAssetV2;
    }

    return (
      maybeRenderedAssetV2 && (
        <a
          css={styles.link}
          href={assetData.url}
          target="_blank"
          rel="noopener noreferrer"
          data-e2e="asset-download-link"
        >
          {maybeRenderedAssetV2}
        </a>
      )
    );
  };

  return (
    <div {...attributes} className={className}>
      <div
        role={!readonly ? 'button' : undefined}
        ref={ref}
        data-e2e="slate-asset-wrapper"
        contentEditable={false}
        onClickCapture={onClick}
      >
        {renderAsset()}
      </div>
      {children}
    </div>
  );
});

export default Asset;
