import type { Placement } from 'bundles/common/components/Tooltip/types';

export const ARROW_OFFSET = 7;
export const TRANSITION_MS = 150;

export const ARROW_MAIN_AXIS: Record<Placement, Placement> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

export const ARROW_CROSS_AXIS: Record<Placement, Placement> = {
  top: 'left',
  right: 'top',
  bottom: 'left',
  left: 'top',
};
