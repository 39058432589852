import * as React from 'react';
import type { ChangeHook } from 'react-router';
import { Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';
import EnterprisePageWrapper from 'bundles/unified-home-common/pages/enterprise/EnterprisePageWrapper';

const EnterpriseHomePageRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/enterprise/home/EnterpriseHomePageRoot')
);

const EnterpriseMyLearningRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/enterprise/my-learning/EnterpriseMyLearningRoot')
);

const EnterpriseSkillSetsRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/enterprise/skillsets/EnterpriseSkillSetsPageRoot')
);

const EnterpriseRecsRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/enterprise/recommendations/EnterpriseRecsPageRootNonUnified')
);

const EnterpriseOnboardingRoot = loadOnRoute(
  () => import('bundles/unified-home-common/pages/enterprise/onboarding/EnterpriseOnboardingPageRootNonUnified')
);

const scrollToTopOnNavigate: ChangeHook = (prevState, nextState) => {
  if (nextState.location.action !== 'PUSH') {
    // history navigation
    return;
  }

  if (nextState.location.pathname !== prevState.location.pathname) {
    // always on path navigation
    window.scrollTo(0, 0);
  }
};

export default (
  <CourseraRoute
    onChange={scrollToTopOnNavigate}
    handle={{
      tracking: {
        data: (params) => ({
          page: { segment: 'enterprise' },
          enterprise: {
            ...(params?.programSlug ? { programSlug: params.programSlug } : {}),
          },
        }),
      },
    }}
  >
    <Route path="/" component={EnterprisePageWrapper}>
      <CourseraRoute
        path="programs/:programSlug"
        getComponent={EnterpriseHomePageRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs-unified/:programSlug"
        getComponent={EnterpriseHomePageRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs/:programSlug/my-learning"
        getComponent={EnterpriseMyLearningRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_my_learnings' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs-unified/:programSlug/my-learning"
        getComponent={EnterpriseMyLearningRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_my_learnings' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs/:programSlug/skillsets"
        getComponent={EnterpriseSkillSetsRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_skillsets' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs-unified/:programSlug/skillsets"
        getComponent={EnterpriseSkillSetsRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_skillsets' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs/:programSlug/recommendations"
        getComponent={EnterpriseRecsRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_recommendations' },
            }),
          },
        }}
      />
      <CourseraRoute
        path="programs-unified/:programSlug/recommendations"
        getComponent={EnterpriseRecsRoot}
        handle={{
          tracking: {
            data: () => ({
              page: { type: 'lihp_recommendations' },
            }),
          },
        }}
      />
      <Route path="programs/:programSlug/onboarding" getComponent={EnterpriseOnboardingRoot} />
      <Route path="programs-unified/:programSlug/onboarding" getComponent={EnterpriseOnboardingRoot} />
    </Route>
    <Route path="*" component={NotFound} />
  </CourseraRoute>
);
