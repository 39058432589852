import { createContext, useContext } from 'react';

import type { WidgetManagerType } from 'bundles/cml/shared/utils/WidgetManager';

export type WidgetContextType = {
  widgetManager?: WidgetManagerType;
};

export const WidgetContext = createContext<WidgetContextType>({
  widgetManager: {
    cache: {},
    previewWidgetSession: () => Promise.resolve(undefined),
    getWidgetSession: () => Promise.resolve(undefined),
  },
});

export const useWidgetContext = () => useContext(WidgetContext);
