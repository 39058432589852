import { Children, isValidElement, useEffect } from 'react';
import type React from 'react';

import type { FloatingContext } from '@floating-ui/react';

import logger from 'js/app/loggerSingleton';

export const getOnlyChild = (children: React.ReactNode): JSX.Element | undefined => {
  try {
    const child = Children.only(children);
    return isValidElement(child) ? child : undefined;
  } catch (err) {
    logger.error(err);
    return undefined;
  }
};

type Options = {
  enabled: boolean;
};

export const useDismissOnResize = (
  { open, onOpenChange }: FloatingContext,
  { enabled }: Options = { enabled: true }
) => {
  useEffect(() => {
    const handleResize = () => onOpenChange(false);

    if (open && enabled) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [open, enabled, onOpenChange]);
};

export const mergeRefs = <T = HTMLElement>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> => {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null && typeof ref !== 'string') {
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};
