import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import { isInternalDomain } from 'bundles/cml/shared/components/link/utils';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';

type Props = RenderElementProps & React.HTMLAttributes<HTMLAnchorElement>;

const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ element, attributes, children, onClick, onKeyDown, ...linkAttributes }, ref) => {
    const link = element as LinkElement;
    const { href, title } = link;
    const defaultLinkAttributes = {
      target: '_blank',
      ...(isInternalDomain(href) ? {} : { rel: 'noopener nofollow noreferrer' }),
      href,
      // https://coursera.atlassian.net/browse/ACCESS-2353 older editing flows for links were setting link titles to the string "undefined" if they weren't set which would cause JAWS to announce the link label as undefined
      title: title === 'undefined' ? undefined : title,
    };

    return (
      <span {...attributes}>
        <a
          {...defaultLinkAttributes}
          {...linkAttributes}
          onClickCapture={onClick}
          onKeyDown={onKeyDown}
          tabIndex={0}
          ref={ref}
        >
          {children}
        </a>
      </span>
    );
  }
);

export default Link;
