import type { Descendant } from 'slate';

import type { BlockElement, CodeElement } from 'bundles/cml/shared/types/elementTypes';
import { isElement } from 'bundles/cml/shared/utils/slateUtils';

export const getCodeBlockIndices = (cml: BlockElement[] | Descendant[]) => {
  const codeElements = cml.filter((element) => isElement(element) && element.type === 'code') as CodeElement[];

  return codeElements.reduce((acc: Record<string, number>, codeBlock, index) => {
    acc[codeBlock.id] = index + 1;
    return acc;
  }, {});
};
