import { useCallback, useEffect, useRef, useState } from 'react';

import { useCourseContext, useRouter } from 'bundles/cml/shared/hooks/context';
import type {
  WidgetSession,
  WidgetSessionDraftConfig,
  WidgetSessionPreview,
  WidgetSessionUpdate,
} from 'bundles/cml/shared/types/widgetManager';
import type { WidgetManagerType } from 'bundles/cml/shared/utils/WidgetManager';

type Options = {
  updateWidgetSession?: (
    widgetSession: WidgetSession,
    iframeTitle: string,
    id: string,
    config: WidgetSessionDraftConfig,
    courseId: string
  ) => Promise<WidgetSessionUpdate | undefined>;
};

export const useWidget = (id: string, widgetManager?: WidgetManagerType, { updateWidgetSession }: Options = {}) => {
  const [widgetPreview, setWidgetPreview] = useState<WidgetSessionPreview>();
  const [widgetSession, setWidgetSession] = useState<WidgetSession>();
  const { courseContext } = useCourseContext();
  const {
    params: { itemId },
  } = useRouter();
  const lastPublishedConfig = useRef<WidgetSessionDraftConfig>();

  // learner side doesn't have courseContext, but will render the widget just fine as long as we're passing a truthy value.
  const courseId = courseContext?.courseId || 'courseId';

  useEffect(() => {
    setWidgetPreview(undefined);
    widgetManager?.previewWidgetSession(id, courseId, itemId).then(setWidgetPreview);
  }, [courseId, itemId, id, widgetManager]);

  useEffect(() => {
    widgetManager?.getWidgetSession({ id, courseId }).then(setWidgetSession);
  }, [id, widgetManager, courseId]);

  useEffect(() => {
    const { metadata } = widgetSession || {};

    // We set the last published config to be what the configuration the iframe shows.
    if (!lastPublishedConfig.current || metadata?.createdAt === metadata?.updatedAt || widgetSession?.isPublished) {
      lastPublishedConfig.current = widgetPreview?.configuration;
    }
  }, [widgetSession, widgetPreview]);

  const handleUpdateWidgetSession = useCallback(
    (session, iframeTitle, sessionId, config) => {
      updateWidgetSession?.(session, iframeTitle, sessionId, config, courseId).then((result) => {
        setWidgetSession(result?.elements?.[0]);
        setWidgetPreview(widgetPreview ? { ...widgetPreview, configuration: { ...config } } : undefined);
      });
    },
    [updateWidgetSession, widgetPreview, courseId]
  );

  return { widgetPreview, widgetSession, handleUpdateWidgetSession, lastPublishedConfig: lastPublishedConfig.current };
};
