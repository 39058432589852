/* eslint-disable no-empty-function */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-param-reassign */
import _t from 'i18n!nls/cml';

// this file was copied from https://github.com/mozilla/pdf.js/blob/master/web/pdf_link_service.js and modified to fit our needs.
// a lot of the empty functions are purely just stubs for additional annotation functionality which we currently do not support.
// only the Link Annotations are currently hooked up

const DEFAULT_LINK_REL = 'noopener noreferrer nofollow';

function addLinkAttributes(
  link: HTMLAnchorElement,
  { url, rel, enabled = true }: { url?: string; target?: number; rel?: string; enabled?: boolean } = {}
) {
  if (enabled && url) {
    link.href = url;
    link.title = url;
  } else {
    link.href = '';
    link.title = _t('Disabled: #{url}', { url });
    link.onclick = () => false;
  }
  link.target = '_blank';

  link.rel = typeof rel === 'string' ? rel : DEFAULT_LINK_REL;
}

export class SimpleLinkService {
  externalLinkEnabled = false;

  constructor() {
    this.externalLinkEnabled = true;
  }

  get pagesCount() {
    return 0;
  }

  get page() {
    return 0;
  }

  set page(value) {}

  get rotation() {
    return 0;
  }

  set rotation(value) {}

  get isInPresentationMode() {
    return false;
  }

  async goToDestination(dest: string | string[]) {}

  goToPage(val: number | string) {}

  addLinkAttributes(link: HTMLAnchorElement, url: string) {
    addLinkAttributes(link, { url, enabled: this.externalLinkEnabled });
  }

  getDestinationHash(dest: object) {
    return '#';
  }

  getAnchorUrl(hash: string) {
    return '#';
  }

  setHash(hash: string) {}

  executeNamedAction(action: string) {}

  executeSetOCGState(action: object) {}

  cachePageRef(pageNum: number, pageRef: object) {}
}
