import * as React from 'react';

import ImageOutput from 'bundles/code-evaluator/components/ImageOutput';
import StringOutput from 'bundles/code-evaluator/components/StringOutput';
import TestCaseOutput from 'bundles/code-evaluator/components/TestCaseOutput';
import type EvaluationResponse from 'bundles/code-evaluator/models/EvaluationResponse';

interface SuccessOutputProps {
  evaluationResponse: EvaluationResponse;
}

const SuccessOutput: React.FC<SuccessOutputProps> = ({ evaluationResponse }) => {
  const { stringOutput, testCaseOutput, imageDataOutput } = evaluationResponse;

  return (
    <div className="rc-SuccessOutput">
      {stringOutput && <StringOutput output={stringOutput} />}
      {testCaseOutput && <TestCaseOutput output={testCaseOutput} />}
      {imageDataOutput && <ImageOutput output={imageDataOutput} />}
    </div>
  );
};

export default SuccessOutput;
