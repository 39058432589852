import { MATH_REGEX } from 'bundles/cml/legacy/models/CMLParser';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { TextVariant } from 'bundles/cml/shared/types/coreTypes';
import type {
  BaseElement,
  BlockElement,
  Marks,
  MathElement,
  Text,
  TextElement,
} from 'bundles/cml/shared/types/elementTypes';
import { isElement, isText } from 'bundles/cml/shared/utils/slateUtils';

const MATCHES = {
  FORMULA: 0,
  MATH_INLINE: 1,
  MATH_BLOCK: 3,
  MATH_INLINE_VARIANT: 5,
  MATH_BLOCK_WITHOUT_DELIMS: 7,
} as const;

export const deserializeMath = (text: string, marks: Marks): { nodes: (Text | MathElement)[]; hasMath: boolean } => {
  const matches = Array.from(text.matchAll(MATH_REGEX));
  if (matches.length <= 0) {
    return { nodes: [{ text, ...marks } as Text], hasMath: false };
  }

  let lastIndex = 0;
  const nodes: (Text | MathElement)[] = [];

  matches.forEach((match: RegExpMatchArray) => {
    const index = match.index || 0;
    const formula = match[MATCHES.FORMULA];
    if (index > lastIndex) {
      nodes.push({ text: text.substring(lastIndex, index), ...marks });
    }

    const isMathBlockWithoutDelims = !!match[MATCHES.MATH_BLOCK_WITHOUT_DELIMS];
    const isMathBlockWithDelims = !!match[MATCHES.MATH_BLOCK];
    const isMathBlock = isMathBlockWithDelims || isMathBlockWithoutDelims;

    nodes.push({
      type: isMathBlock ? BLOCK_TYPES.MATH_BLOCK : BLOCK_TYPES.MATH_INLINE,
      isInline: !isMathBlock ? true : undefined,
      isVoid: true,
      formula: isMathBlockWithoutDelims ? `\\[${formula}\\]` : formula,
      children: [{ text: '' }],
    } as MathElement);

    lastIndex = index + formula.length;
  });

  if (lastIndex < text.length) {
    nodes.push({ text: text.substr(lastIndex), ...marks });
  }

  return { nodes, hasMath: true };
};

export const hasMathBlocks = (nodes: (Text | BaseElement)[]) => {
  return nodes.some((el) => isElement(el) && el.type === BLOCK_TYPES.MATH_BLOCK);
};

// normalize text containing math blocks into block level elements
// this must be done because Slate does not support mixing inline and text nodes with block level nodes
export const normalizeTextWithMathBlocks = (
  children: (Text | BaseElement)[],
  variant?: TextVariant
): BlockElement[] => {
  return children.reduce((result: BlockElement[], child: Text | BaseElement) => {
    if (isText(child) || child.isInline) {
      const prevElement = result[result.length - 1];
      if (prevElement?.type === BLOCK_TYPES.TEXT) {
        prevElement.children.push(child);
      } else {
        result.push({ type: BLOCK_TYPES.TEXT, children: [child], variant } as TextElement);
      }
      return result;
    }

    result.push(child as BlockElement);
    return result;
  }, []);
};
