/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import PdfViewer from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewer';
import type { AssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetElement } from 'bundles/cml/shared/types/elementTypes';

type Props = {
  assetData: AssetData;
  assetElement: AssetElement;
};

const styles = {
  pdfEmbeddedAsset: css`
    overflow: auto;
    white-space: nowrap;
  `,
};

export const EmbeddablePdfAsset: React.FC<Props> = ({ assetData, assetElement }) => {
  return (
    <div className="cml-asset cml-asset-pdf" css={styles.pdfEmbeddedAsset}>
      <PdfViewer
        assetId={assetElement.id}
        url={assetData.url}
        pageStart={assetElement.embedStartPage}
        pageEnd={assetElement.embedEndPage}
      />
    </div>
  );
};
