import type { Cml_SubtitleTrack } from '__generated__/graphql-types';

import { VIDEO_RESOLUTIONS } from 'bundles/asset-admin/constants/AssetTypes';
import type { VideoResolution } from 'bundles/asset-admin/types/assets';
import type { VideoResolutions } from 'bundles/cml/legacy/types/assets';
import type { AssetData, ImageAssetData, VideoAssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { Asset, VideoAsset } from 'bundles/cml/shared/types/assetTypes';
import { getAssetTag } from 'bundles/cml/shared/utils/assetUtils';
import { maybeStripProtocolAndDomain } from 'bundles/common/utils/urlUtils';

type AssetAttributes = {
  name: string;
  extension: string;
  assetType: string;
  id: string;
};

const getNameAndExtension = (asset: Asset) => {
  const filename = asset.filename || asset.name;
  let name = filename;
  let extension = asset.fileExtension || '';

  const lastDotIndex = filename.lastIndexOf('.');

  if (lastDotIndex > -1) {
    name = filename.substring(0, lastDotIndex);
    extension = extension || filename.substring(lastDotIndex + 1);
  }

  return { name, extension };
};

export const getAssetAttributes = (asset?: Asset): AssetAttributes => {
  if (!asset) {
    return { id: '', name: '', extension: '', assetType: '' };
  }

  const { name, extension } = getNameAndExtension(asset);
  const { typeName: assetType, id } = asset;

  return {
    name,
    extension,
    assetType,
    id,
  };
};

export const assetToVideoAssetData = (asset: VideoAsset): VideoAssetData => {
  const {
    typeName: type,
    url: { url },
    videoThumbnailUrls,
    videoSourceUrls,
  } = asset;

  const { name, id, extension } = getAssetAttributes(asset);
  const resolutions = VIDEO_RESOLUTIONS.reduce((result, resolution: VideoResolution) => {
    const assetSource = videoSourceUrls?.[resolution];

    // eslint-disable-next-line no-param-reassign
    result[resolution] = {
      webMVideoUrl: assetSource?.['video/webm']?.url || url,
      mp4VideoUrl: assetSource?.['video/mp4']?.url || url,
      previewImageUrl: videoThumbnailUrls?.[resolution]?.[0]?.url || '',
    };

    return result;
  }, {} as VideoResolutions);

  return {
    id,
    url,
    type,
    name,
    extension,
    resolutions,
  };
};

export const stripProtocolAndDomain = (subtitleTrack: Cml_SubtitleTrack) => {
  return {
    ...subtitleTrack,
    ...(subtitleTrack.url ? { url: maybeStripProtocolAndDomain(subtitleTrack.url) } : {}),
  };
};

export const stripProtocolAndDomains = (subtitleTracks: Array<Cml_SubtitleTrack>) => {
  return subtitleTracks.map(stripProtocolAndDomain);
};

export const subtitleTracksToDataAttribute = (
  subtitleTracks: Array<Cml_SubtitleTrack> | null | undefined
): string | undefined => {
  if (subtitleTracks?.length) {
    return JSON.stringify(subtitleTracks);
  } else {
    return undefined;
  }
};

export const subtitleTracksToVideoPlayerSubtitlesVttAttribute = (
  subtitleTracks?: Array<Cml_SubtitleTrack>
): Record<string, string> | undefined => {
  if (subtitleTracks?.length) {
    return subtitleTracks.reduce((accumulator: Record<string, string>, { languageCode, url }) => {
      accumulator[languageCode] = url;
      return accumulator;
    }, {});
  } else {
    return undefined;
  }
};

export const dataAttributeToSubtitleTracks = (
  dataSubtitleTracks: string | null | undefined
): Array<Cml_SubtitleTrack> => {
  if (dataSubtitleTracks) {
    return JSON.parse(dataSubtitleTracks);
  } else {
    return [];
  }
};

const assetToImageAssetData = (asset: Asset): ImageAssetData => {
  const {
    url: { url },
    typeName: type,
  } = asset;

  return {
    url,
    type,
    alt: getAssetTag(asset, 'description'),
    description: getAssetTag(asset, 'longDescription'),
  };
};

export const assetToAssetData = (asset: Asset): AssetData | VideoAssetData | ImageAssetData => {
  if (asset.typeName === 'video') {
    return assetToVideoAssetData(asset as VideoAsset);
  }

  if (asset.typeName === 'image') {
    return assetToImageAssetData(asset);
  }

  const { name, id, extension } = getAssetAttributes(asset);

  const {
    url: { url },
    typeName: type,
  } = asset;

  return {
    id,
    url,
    type,
    name,
    extension,
    label: `${name}.${extension}`,
  };
};
