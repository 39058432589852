/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import { MaximizeIcon, MoreHorizontalFilledIcon } from '@coursera/cds-icons';

import { FloatingTooltip } from 'bundles/common/components/Tooltip';

import _t from 'i18n!nls/cml';

const styles = {
  buttonContainer: css`
    padding: var(--cds-spacing-100);
    display: flex;
    gap: var(--cds-spacing-50);
  `,
  expand: css`
    padding: var(--cds-spacing-150) var(--cds-spacing-200);
  `,
};

type Props = {
  onClick?: () => void;
  onExpand?: () => void;
};

const WidgetButtonContainer = React.forwardRef<HTMLButtonElement, Props>(({ onClick, onExpand }, ref) => {
  return (
    <div css={styles.buttonContainer}>
      <FloatingTooltip message={_t('Expand plugin')}>
        <Button css={styles.expand} variant="secondary" onClick={onExpand} data-pendo="cml-widget-expand-button">
          <MaximizeIcon />
        </Button>
      </FloatingTooltip>
      {onClick && (
        <FloatingTooltip message={_t('More options')}>
          <Button ref={ref} variant="ghost" onClickCapture={onClick} data-pendo="cml-widget-more-button">
            <MoreHorizontalFilledIcon />
          </Button>
        </FloatingTooltip>
      )}
    </div>
  );
});

export default WidgetButtonContainer;
