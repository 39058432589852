import { DOMParser } from 'xmldom';

import logger from 'js/app/loggerSingleton';

import inServerContext from 'bundles/ssr/util/inServerContext';

// Node does not exist in ssr
export const { TEXT_NODE, ELEMENT_NODE } = inServerContext ? { TEXT_NODE: 3, ELEMENT_NODE: 1 } : window.Node;

// Logger does not work in ssr
const XML_ERROR_HANDLERS = {
  errorHandler: inServerContext ? console.warn : logger.warn.bind(logger),
};

export const parseDOM = (text: string, mimeType: string) => {
  const xmlDoc = new DOMParser(XML_ERROR_HANDLERS).parseFromString(text, mimeType);
  return Array.from(Array.from(xmlDoc.childNodes).find((node) => node.nodeType === ELEMENT_NODE)?.childNodes ?? []);
};
