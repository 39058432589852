import * as React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import config from 'js/app/config';
import path from 'js/lib/path';

import _t from 'i18n!nls/alice';

import 'css!./__styles__/TipIcon';

class TipIcon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
    const { className } = this.props;
    const TIP_ICON_SRC = path.join(config.url.assets, 'images/alice/tip_icon.svg');

    return (
      <div className={classNames('rc-TipIcon', className)}>
        <div className="icon-container horizontal-box align-items-absolute-center">
          <img src={TIP_ICON_SRC} height="60%" alt={_t('Lightbulb tip')} />
        </div>
      </div>
    );
  }
}

export default TipIcon;
