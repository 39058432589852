import { keys } from 'lodash';

import { randomUUID } from 'js/lib/uuid';

import { AssetTypeNames, isAssetTypeName } from 'bundles/asset-admin/types/assets';
import type CMLVariableNames from 'bundles/cml/legacy/constants/CMLVariableNames';
import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import type { VariableData } from 'bundles/cml/legacy/types/Content';
import { BLACKLIST_TEXT_REGEX, BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { AssetData, ImageAssetData, VideoAssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { ImageSizes, TextVariant } from 'bundles/cml/shared/types/coreTypes';
import type {
  BaseElement,
  BlockElement,
  FillableBlankElement,
  HeadingElement,
  ImageElement,
  LegacyAudioElement,
  LinkElement,
  ListElement,
  ListItemElement,
  Marks,
  PersonalizationTagElement,
  TableCellElement,
  TableRowElement,
  Text,
  TextElement,
} from 'bundles/cml/shared/types/elementTypes';
import { deserializeMath, hasMathBlocks, normalizeTextWithMathBlocks } from 'bundles/cml/shared/utils/deserializeMath';
import { ELEMENT_NODE, TEXT_NODE, parseDOM } from 'bundles/cml/shared/utils/domUtils';
import { isVoidOrTable } from 'bundles/cml/shared/utils/slateUtils';

const CML_MARKS = {
  strong: 'bold',
  em: 'italic',
  u: 'underline',
  var: 'monospace',
  sup: 'superscript',
  sub: 'subscript',
} as const;

type VariableKey = keyof typeof CMLVariableNames;

export const withVariableSubstitutions = (cmlString: string, variableData: VariableData) => {
  return (Object.keys(variableData) as VariableKey[]).reduce((result: string, key) => {
    const value = variableData[key] as string;
    return result.replace(new RegExp(`%${key}%`, 'g'), value);
  }, cmlString);
};

export const createEmptyBlock = (): TextElement => {
  return {
    type: 'text',
    children: [{ text: '' }],
  } as TextElement;
};

// returns text for an element recursively
export const getText = (node?: Node): string => {
  if (!node) {
    return '';
  }

  if (node.nodeType === TEXT_NODE) {
    return node.textContent || '';
  }

  return node.childNodes ? [...Array.from(node.childNodes).map((e) => getText(e))].join('') : '';
};

// returns all the marks for an element recursively
export const getMarks = (node: Node | undefined, nodeName: string): Marks => {
  const marks: Marks = {};
  if (!node) {
    return marks;
  }

  if (node.nodeType === TEXT_NODE) {
    // single-level marked text
    const mark = CML_MARKS[nodeName as keyof typeof CML_MARKS];
    marks[mark] = true;
  } else if (node.nodeType === ELEMENT_NODE) {
    // for nested marks, add current level mark
    const mark = CML_MARKS[node.nodeName as keyof typeof CML_MARKS];
    marks[mark] = true;

    // traverse and recursively add nested marks
    Array.from(node.childNodes).forEach((e) => {
      if (e.nodeName) {
        Object.assign(marks, getMarks(e, node.nodeName));
      }
    });
  }

  return marks;
};

const getTextNode = (element: Element, children: (BaseElement | Text)[]): BlockElement[] => {
  const variant = element.getAttribute('variant') || undefined;
  if (hasMathBlocks(children)) {
    return normalizeTextWithMathBlocks(children, variant as TextVariant);
  }

  return [
    {
      type: BLOCK_TYPES.TEXT,
      variant,
      children,
    } as TextElement,
  ];
};

// recursively traverses the node and gets all child nodes
export const getNodes = <T extends BaseElement | Text>(node?: Node, previousMarks: Marks = {}): Array<T> => {
  const content: Array<BaseElement | Text> = [];

  if (!node || !node.childNodes.length) {
    return [{ text: '' }] as T[];
  }

  Array.from(node.childNodes).forEach((childNode: Node) => {
    if (childNode.nodeType === TEXT_NODE) {
      const text = childNode.textContent || getText(childNode);
      const { nodes } = deserializeMath(text, previousMarks);
      content.push(...nodes);
    } else if (childNode.nodeType === ELEMENT_NODE) {
      const element = childNode as Element;
      const nodeName = childNode.nodeName;

      if (keys(CML_MARKS).includes(nodeName)) {
        const markName = CML_MARKS[nodeName as keyof typeof CML_MARKS];

        // convert text nodes passing down its own mark and maintaining previousMarks from parents
        content.push(...getNodes(childNode, { ...previousMarks, [markName]: true }));
      }

      switch (childNode.nodeName) {
        case 'fillable-blank':
          content.push({
            type: BLOCK_TYPES.FILLABLE_BLANK,
            isInline: true,
            isVoid: true,
            innerText: getText(childNode) || 'Response',
            uuid: element.getAttribute('uuid') || undefined,
            responseType: element.getAttribute('type') || undefined,
            children: [{ text: '' }],
          } as FillableBlankElement);
          break;

        case 'a':
          content.push({
            type: BLOCK_TYPES.LINK,
            children: getNodes(childNode, { ...previousMarks }),
            isInline: true,
            href: element.getAttribute('href') || '',
            title: element.getAttribute('title') || undefined,
          } as LinkElement);
          break;

        case 'li':
          content.push({
            type: BLOCK_TYPES.LIST_ITEM,
            children: getNodes(childNode, { ...previousMarks }),
            'aria-level': element.getAttribute('aria-level') || undefined,
            'aria-posinset': element.getAttribute('aria-posinset') || undefined,
            'data-aria-level': element.getAttribute('data-aria-level') || undefined,
            'data-aria-posinset': element.getAttribute('data-aria-posinset') || undefined,
          } as ListItemElement);
          break;

        case 'list':
          content.push({
            type: element.getAttribute('bulletType') === 'bullets' ? BLOCK_TYPES.BULLET_LIST : BLOCK_TYPES.NUMBER_LIST,
            children: getNodes(childNode, { ...previousMarks }) as ListItemElement[],
          });
          break;

        case 'tr':
          content.push({
            type: BLOCK_TYPES.TABLE_ROW,
            children: getNodes(childNode, { ...previousMarks }),
          });
          break;

        case 'thead':
          content.push({
            type: BLOCK_TYPES.TABLE_ROW,
            children: getNodes(childNode.childNodes[0], { ...previousMarks }),
          });
          break;

        case 'th':
        case 'td':
          content.push({
            type: BLOCK_TYPES.TABLE_CELL,
            header: childNode.nodeName === 'th' || undefined,
            children: getNodes(childNode, { ...previousMarks }),
          } as TableCellElement);
          break;

        // This is a special case where we want to render a personalization tag into the editor.
        // <tag> is not a supported tag in the CML dtd, it is just used for initial CML -> slate conversions to make rendering a tag object easier
        // For reference on usage, please see bundles/enterprise-admin-messages/constants.ts
        // End goal is to send these tags as <text> with % wrapping around it so it can be handled by variable substitution
        case 'ptag':
          content.push({
            type: BLOCK_TYPES.PERSONALIZATION_TAG,
            isInline: true,
            isVoid: true,
            tagValue: getText(childNode),
            children: [{ text: '' }],
          } as PersonalizationTagElement);
          break;

        case 'text': {
          const children = getNodes(element, { ...previousMarks });
          content.push(...getTextNode(element, children));
          break;
        }

        default:
          break;
      }
    } else {
      console.error('> Unsupported type: ', childNode.nodeName); // eslint-disable-line no-console
    }
  });

  const firstNode = content[0] as BaseElement;
  if (firstNode?.isInline) {
    content.unshift({ text: '' });
  }

  const lastNode = content[content.length - 1] as BaseElement;
  if (lastNode?.isInline) {
    content.push({ text: '' });
  }

  return content as T[];
};

const getImageElement = (element: Element, imageAssets?: (ImageAssetData | undefined)[]): ImageElement => {
  const assetData = imageAssets?.shift();

  return {
    type: BLOCK_TYPES.IMAGE,
    isVoid: true,
    id: element.getAttribute('assetId') || element.getAttribute('id') || undefined,
    size: (element.getAttribute('size') as ImageSizes) || undefined,
    src: element.getAttribute('src') || undefined,
    assetData,
    children: [{ text: '' }],
  };
};

export const getNode = (
  node?: Node,
  imageAssets?: (ImageAssetData | undefined)[],
  assets?: Record<string, AssetData | undefined>
): BlockElement | null | (BlockElement | null)[] => {
  if (node?.nodeType !== ELEMENT_NODE) {
    return null;
  }

  const element = node as Element;
  switch (node.nodeName) {
    case 'text': {
      const children = getNodes(element);
      return getTextNode(element, children);
    }

    case 'audio': {
      const src = element.getAttribute('src');
      const extensionIndex = src?.lastIndexOf('.');
      return {
        type: BLOCK_TYPES.LEGACY_AUDIO,
        isVoid: true,
        id: element.getAttribute('assetId') || element.getAttribute('id') || undefined,
        src: element.getAttribute('src') || '',
        name: element.getAttribute('caption'),
        assetType: 'audio',
        assetData: {
          id: 'audioId',
          name: element.getAttribute('caption'),
          type: 'audio',
          extension: extensionIndex ? element.getAttribute('src')?.substring(extensionIndex + 1) : undefined,
          url: element.getAttribute('src') || '',
        },
        children: [{ text: '' }],
      } as LegacyAudioElement;
    }

    case 'heading': {
      return {
        type: BLOCK_TYPES.HEADING,
        children: getNodes(node),
        level: element.getAttribute('level') ?? '1',
        variant: element.getAttribute('variant') || undefined,
      } as HeadingElement;
    }

    case 'list': {
      return {
        type: element.getAttribute('bulletType') === 'bullets' ? BLOCK_TYPES.BULLET_LIST : BLOCK_TYPES.NUMBER_LIST,
        children: getNodes(node),
      } as ListElement;
    }

    case 'img': {
      return getImageElement(element, imageAssets);
    }

    case 'asset': {
      const id = element.getAttribute('id') ?? '';
      if (!id) {
        return null;
      }

      const maybeAssetTypeName = element.getAttribute('assetType');

      if (maybeAssetTypeName === AssetTypeNames.IMAGE) {
        return getImageElement(element, imageAssets);
      }

      return {
        type: BLOCK_TYPES.ASSET,
        isVoid: true,
        id,
        name: element.getAttribute('name') || undefined,
        extension: element.getAttribute('extension') || undefined,
        ...(isAssetTypeName(maybeAssetTypeName) ? { assetType: maybeAssetTypeName } : {}),
        assetData: assets?.[id],
        children: [{ text: '' }],
        embedEnabled: element.getAttribute('embedEnabled') === 'true',
        embedStartPage: Number(element.getAttribute('embedStartPage')) || undefined,
        embedEndPage: Number(element.getAttribute('embedEndPage')) || undefined,
      };
    }

    case 'table': {
      const tableNodes = getNodes(node) as TableRowElement[];
      const [firstElement, secondElement] = Array.from(node.childNodes);
      const hasCaption = firstElement?.nodeName === 'caption';
      const hasHeader = hasCaption ? secondElement?.nodeName === 'thead' : firstElement?.nodeName === 'thead';

      return {
        type: BLOCK_TYPES.TABLE,
        children: tableNodes,
        headless: !hasHeader, // EditTable plugin needs this attribute to render correctly
      };
    }

    case 'code': {
      const codeText = node.childNodes[0]?.textContent || '';
      return {
        type: BLOCK_TYPES.CODE,
        isVoid: true,
        codeText,
        id: element.getAttribute('id') || randomUUID(),
        name: element.getAttribute('name') || undefined,
        language: (element.getAttribute('language') || 'javascript') as LanguageType,
        evaluatorId: element.getAttribute('evaluatorId') || undefined,
        children: [{ text: '' }],
      };
    }

    case 'widget': {
      const id = element.getAttribute('id');
      if (id == null) {
        return null;
      }

      return {
        type: BLOCK_TYPES.WIDGET,
        isVoid: true,
        id,
        children: [{ text: '' }],
      };
    }

    default:
      return null;
  }
};

export const shouldAddEmptyBlock = (blocks: BlockElement[]) => {
  const lastNode: BlockElement | undefined = blocks[blocks.length - 1];
  return !lastNode || isVoidOrTable(lastNode);
};

export const cmlToSlate = (
  cmlString: string,
  imageAssets?: (ImageAssetData | undefined)[],
  assets?: Record<string, AssetData | VideoAssetData | undefined>,
  variableData?: VariableData
) => {
  if (!cmlString) {
    return [createEmptyBlock()];
  }

  let normalizedCML = cmlString.replace(BLACKLIST_TEXT_REGEX, '');
  if (variableData) {
    normalizedCML = withVariableSubstitutions(normalizedCML, variableData);
  }

  const xmlNodes = parseDOM(normalizedCML, 'application/xml');
  const images = imageAssets ? [...imageAssets] : undefined;

  const slateNodes = xmlNodes
    .map((node) => getNode(node, images, assets))
    .flat()
    .filter((node: BlockElement | null) => !!node) as BlockElement[];

  if (shouldAddEmptyBlock(slateNodes)) {
    slateNodes.push(createEmptyBlock());
  }

  return slateNodes;
};
