export const zIndex = {
  // CDS does not currently expose this value, but this is the z-index for modals in CDS, and this
  // is required to have modals display over an item editor's TunnelVision.
  // https://github.com/webedx-spark/cds/blob/main/packages/cds-core/src/theme/createZIndex.tsx#L12
  modal: 10000,
  xxxl: 3000, // e.g. above .rc-CourseAuthoringStateBanner
  xxl: 100, // e.g. top-most header-container, modals
  xl: 10, // e.g. headers
  lg: 9, // e.g. footers that are still high but go under headers
  md: 7, // e.g. higher on page content but below footer, like toasts
  sm: 5,
  xs: 1,
};
