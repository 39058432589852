import type { PdfPage } from 'bundles/cml/shared/components/asset/pdfViewer/types';

export function getDevicePixelRatio() {
  return window.devicePixelRatio || 1;
}

export const pdfJsRenderPage = ({
  canvas,
  currentPage,
  zoom,
}: {
  canvas: HTMLCanvasElement | null;
  currentPage: PdfPage | null;
  zoom: number;
}): Promise<void> => {
  if (!canvas || !currentPage) {
    return Promise.reject();
  }

  const devicePixelRatio = getDevicePixelRatio();

  // we separate out render viewport from the screen viewport to handle high DPI screens correctly
  // this lets us upscale the canvas drawing and then scale it down for display ensuring a crisper text
  const viewport = currentPage.getViewport({ scale: zoom });

  const transform = devicePixelRatio !== 1 ? [devicePixelRatio, 0, 0, devicePixelRatio, 0, 0] : null;

  /* eslint-disable no-param-reassign */
  canvas.width = Math.floor(viewport.width * devicePixelRatio);
  canvas.height = Math.floor(viewport.height * devicePixelRatio);

  canvas.style.width = Math.floor(viewport.width) + 'px';
  canvas.style.height = Math.floor(viewport.height) + 'px';
  /* eslint-enable */

  const context = canvas.getContext('2d');
  if (!context) {
    return Promise.reject();
  }

  const renderContext = {
    canvasContext: context,
    viewport,
    transform,
  };

  const renderTask = currentPage.render(renderContext);
  return renderTask.promise;
};
