/** @jsx jsx */
import { css } from '@emotion/react';

import { breakpoints } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

const cdsToCMLStyles = (theme: Theme) => {
  const codeStyle = css`
    pre,
    code {
      border: 1px solid ${theme.palette?.gray[400]};
      border-radius: 0;
      font-size: 0.875rem;
      line-height: 16px;
      margin-bottom: ${theme.spacing?.(16)};
      overflow-y: hidden;
      padding: 0;
    }

    code {
      position: relative;
      display: block;
      margin: 0 0 10px;
      word-break: break-all;
      word-wrap: break-word;
      background-color: ${theme.palette?.gray[200]};
    }
  `;

  const monospaceStyle = css`
    var {
      background-color: ${theme.palette?.gray[300]};
      font-family: Courier, 'Courier New', monospace;
      font-size: 0.95em;
      font-style: normal;
      font-weight: bold;
    }
  `;

  const figureStyle = css`
    figure {
      margin: 0 !important;

      &.selected {
        outline: 2px solid ${theme.palette?.green[500]};
      }

      ::selection {
        background: none;
      }

      img {
        max-width: 100%;
      }
    }
  `;

  const imgStyle = css`
    img {
      max-width: 100%;
      margin: ${theme.spacing?.(0, 0, 24, 0)};

      &.cml-image-small {
        width: 180px;
      }

      &.cml-image-fullWidth {
        width: 100%;
      }
    }
  `;

  const listStyle = css`
    ul {
      list-style-type: disc;
    }

    /* aria-level targeting is used for "fake lists", i.e cases where the list is not
     structurally nested but we need to visually show the nesting e.g. MS Word copy-pasted lists.
  */
    ul ul,
    ul > li[aria-level='2'] {
      list-style-type: circle !important;
    }

    ul ul ul,
    ul > li[aria-level='3'] {
      list-style-type: square !important;
    }

    ul ul ul ul,
    ul > li[aria-level='4'] {
      list-style-type: disc !important;
    }

    ul ul ul ul ul,
    ul > li[aria-level='5'] {
      list-style-type: circle !important;
    }

    ol ol,
    ol > li[aria-level='2'] {
      list-style-type: lower-alpha !important;
    }

    ol ol ol,
    ol > li[aria-level='3'] {
      list-style-type: lower-roman !important;
    }

    ol ol ol ol,
    ol > li[aria-level='4'] {
      list-style-type: decimal !important;
    }

    ol ol ol ol ol,
    ol > li[aria-level='5'] {
      list-style-type: lower-alpha !important;
    }

    // handle list ordering for "fake lists"
    // aria-posinset represents the actual position of the item in the list
    ol li[aria-posinset]::marker {
      content: attr(aria-posinset) '. ';
    }

    ul,
    ol {
      ${theme.typography?.body1}
      padding-left: ${theme.spacing?.(32)};
      margin-left: ${theme.spacing?.(0)};

      li {
        margin-bottom: ${theme.spacing?.(8)};
        padding-left: ${theme.spacing?.(8)};

        ul,
        ol {
          margin-top: ${theme.spacing?.(8)};
        }

        // fake nested lists for cases like MS Word copy-paste
        // where lists are not structurally nested
        &[aria-level='2'] {
          margin-left: 32px !important;
        }

        &[aria-level='3'] {
          margin-left: 64px !important;
        }

        &[aria-level='4'] {
          margin-left: 96px !important;
        }

        &[aria-level='5'] {
          margin-left: 128px !important;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  `;

  const tableStyle = css`
    table {
      margin: ${theme.spacing?.(0, 0, 24, 0)};
      width: 100%;

      th,
      td {
        ${theme.typography?.body2}
        padding: ${theme.spacing?.(4, 16)};
        text-align: left;
      }

      th,
      thead td {
        border: 1px solid ${theme.palette?.gray[800]};
      }

      thead td p,
      thead th p {
        font-weight: bold;
      }

      td {
        border: 1px solid ${theme.palette?.gray[300]};
      }

      p {
        margin-bottom: 0;
      }
    }
  `;

  const cdsFonts = css`
    strong {
      font-family: unset;
    }

    h1,
    h1[data-heading-variant='h1semibold'] {
      ${theme.typography?.h1semibold}
      margin: ${theme.spacing?.(32, 0, 24, 0)};

      :first-of-type {
        margin-top: 0;
      }
    }

    h1[data-heading-variant='h1regular'] {
      ${theme.typography?.h1}
    }

    h2,
    h2[data-heading-variant='h2semibold'] {
      ${theme.typography?.h2semibold}
      margin: ${theme.spacing?.(32, 0, 16, 0)};

      :first-of-type {
        margin-top: 0;
      }
    }

    h2[data-heading-variant='h2regular'] {
      ${theme.typography?.h2}
    }

    h3,
    h3[data-heading-variant='h3bold'] {
      ${theme.typography?.h3bold}
      margin: ${theme.spacing?.(32, 0, 12, 0)};

      :first-of-type {
        margin-top: 0;
      }
    }

    h3[data-heading-variant='h3semibold'] {
      ${theme.typography?.h3semibold}
    }

    h4,
    h4[data-heading-variant='h4bold'] {
      ${theme.typography?.h4bold}
      margin: ${theme.spacing?.(24, 0, 8, 0)};

      :first-of-type {
        margin-top: 0;
      }
    }

    p,
    p[data-text-variant='body1'] {
      ${theme.typography?.body1}
      margin-bottom: ${theme.spacing?.(16)};
      min-height: 24px;
    }

    p[data-text-variant='body2'] {
      ${theme.typography?.body2}
    }

    a {
      color: ${theme.palette?.blue[600]};
      text-decoration: underline;

      &:hover {
        color: ${theme.palette?.blue[700]};
      }
    }
  `;

  const cmlAssets = css`
    .cml-asset {
      width: 100%;
      margin-bottom: ${theme.spacing?.(12)};

      .cml-asset-link {
        width: 100%;
        padding: ${theme.spacing?.(12)};
        display: block;
        color: ${theme.palette?.gray[600]};
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        text-overflow: ellipsis;
        border: 1px solid ${theme.palette?.gray[400]};

        &:hover {
          cursor: pointer;
          border-color: ${theme.palette?.gray[400]};
        }
      }

      a {
        text-decoration: none;
      }

      .asset-container {
        border: 1px solid ${theme.palette?.gray[500]};
        justify-content: space-between;
        display: flex;
        ${breakpoints?.down('xs')} {
          flex-wrap: wrap;
        }

        /* This is done in order to have margin when the flex elements wrap. */
        padding: ${theme.spacing?.(8, 24, 24, 24)};

        > * {
          margin-top: ${theme.spacing?.(16)};
          align-items: center;
        }
      }

      .asset-link-title {
        margin-right: ${theme.spacing?.(8)};
        margin-left: ${theme.spacing?.(32)};
        padding-left: ${theme.spacing?.(8)};
        white-space: nowrap;
      }

      .asset-info {
        margin-left: ${theme.spacing?.(16)};

        .asset-name {
          ${theme.typography?.h3bold}
          text-overflow: ellipsis;
          overflow: hidden;
          display: inline-block;
        }

        .asset-extension {
          ${theme.typography?.body2}
          color: ${theme.palette?.gray[700]};
          margin-top: ${theme.spacing?.(8)};
        }
      }

      .asset-icon-file {
        width: ${theme.spacing?.(24)};
        min-width: ${theme.spacing?.(24)};
      }

      &.cml-asset-video {
        width: auto;
      }

      &.cml-asset-audio {
        width: auto;
        min-width: 300px;
      }
    }

    .cml-asset-link {
      text-decoration: initial;
      color: initial;
    }
  `;

  const globalMapping = css`
    word-wrap: break-word;

    .show-soft-breaks {
      white-space: pre-wrap; /* preserves newlines/soft-breaks */
    }

    /* Don't let the last child have a bottom margin */
    > *:last-child,
    [data-slate-editor='true'] > *:last-child {
      margin-bottom: 0 !important;
    }

    .ace_scroller .ace_content .ace_layer .ace_print-margin {
      background: ${theme.palette?.blue[200]};
    }

    /* for usage, see "display" prop jsdoc in RenderableHtml.tsx */
    .displayInlineBlock .cmlToHtml-content-container.hasAssetBlock,
    .displayInlineBlock .cmlToHtml-content-container.hasCodeBlock {
      display: inline-block;
    }
  `;

  const math = css`
    .katex-display,
    .MathJax_Display {
      margin: 0;
    }

    .MathJax {
      .math {
        > span {
          font-size: 120% !important;
        }
      }
    }
  `;

  return [
    cdsFonts,
    cmlAssets,
    monospaceStyle,
    codeStyle,
    figureStyle,
    imgStyle,
    listStyle,
    tableStyle,
    globalMapping,
    math,
  ];
};

export { cdsToCMLStyles };
