/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import type { MathElement } from 'bundles/cml/shared/types/elementTypes';

const MathFormula = createLoadableComponent(() => import('bundles/cml/shared/components/math/MathFormula'));

const styles = {
  root: css`
    display: inline-block;
  `,
  fullWidth: css`
    width: 100%;
  `,
};

type Props = RenderElementProps & {
  children?: React.ReactNode | ((mathFormula: React.ReactNode) => React.ReactNode);
  onError?: (error?: string) => void;
};

const Math: React.FC<Props> = ({ element, attributes, children, onError = () => undefined }) => {
  const math = element as MathElement;
  const isBlock = !math.isInline;
  const Tag = isBlock ? 'p' : 'span';

  const mathFormula = <MathFormula formula={math.formula} onError={onError} />;
  const isFunction = typeof children === 'function';

  return (
    <Tag {...attributes} data-pendo="math-block" css={[styles.root, isBlock && styles.fullWidth]}>
      {isFunction ? children(mathFormula) : mathFormula}
    </Tag>
  );
};

export default Math;
