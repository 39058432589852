import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import type { HeadingLevel } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingElement } from 'bundles/cml/shared/types/elementTypes';

const HEADING_TAGS: Record<HeadingLevel, keyof React.ReactHTML> = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
};

const Heading: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const { level, variant } = element as HeadingElement;

  const headingTag = HEADING_TAGS[level];
  if (!headingTag) {
    return <span {...attributes}>{children}</span>;
  }

  return React.createElement(headingTag, { ...attributes, 'data-heading-variant': variant }, children);
};

export default Heading;
