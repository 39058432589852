/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useMemo } from 'react';

import { SlateReactPresentation } from 'slate-react-presentation';

import type { Theme } from '@coursera/cds-core';

import { cdsToCMLStyles } from 'bundles/cml/legacy/components/cds/cdsToCMLStyles';
import type { CmlContent as NaptimeCmlContent, VariableData } from 'bundles/cml/legacy/types/Content';
import { getCodeBlockIndices } from 'bundles/cml/shared/components/code/utils';
import type { CmlContent } from 'bundles/cml/shared/types/CmlContent';
import type { AssetManager as AssetManagerType } from 'bundles/cml/shared/types/assetManager';
import AssetManager from 'bundles/cml/shared/utils/AssetManager';
import type { WidgetManagerType } from 'bundles/cml/shared/utils/WidgetManager';
import { createWidgetManager } from 'bundles/cml/shared/utils/WidgetManager';
import { renderWithProviders } from 'bundles/cml/shared/utils/withProviders';
import type { Provider } from 'bundles/cml/shared/utils/withProviders';
import { useCMLToSlate } from 'bundles/cml/viewer/components/hooks/useCMLToSlate';
import { useRenderElement, useRenderTextElement } from 'bundles/cml/viewer/components/hooks/useRenderElement';
import { AssetContext } from 'bundles/cml/viewer/context/assetContext';
import type { AssetContextType } from 'bundles/cml/viewer/context/assetContext';
import { CodeBlockContext } from 'bundles/cml/viewer/context/codeBlockContext';
import type { CodeBlockContextType } from 'bundles/cml/viewer/context/codeBlockContext';
import { FillableBlanksContext } from 'bundles/cml/viewer/context/fillableBlanksContext';
import type { FillableBlanksContextType } from 'bundles/cml/viewer/context/fillableBlanksContext';
import { WidgetContext } from 'bundles/cml/viewer/context/widgetContext';
import type { WidgetContextType } from 'bundles/cml/viewer/context/widgetContext';

export type Props = {
  cml?: CmlContent | NaptimeCmlContent;
  enableFillableBlanksV2?: boolean;
  contentId?: string;
  variableData?: VariableData;
  assetManager?: AssetManagerType;
  widgetManager?: WidgetManagerType;
  videoSubtitlesEnabled?: boolean;
};

const styles = {
  viewer: (theme: Theme) => css`
    ${cdsToCMLStyles(theme)}
    white-space: pre-wrap;
    overflow-wrap: break-word;
  `,
};

const CMLViewer: React.FC<Props> = (props) => {
  const {
    cml,
    contentId,
    variableData,
    assetManager = AssetManager,
    enableFillableBlanksV2,
    widgetManager,
    videoSubtitlesEnabled,
  } = props;
  const value = useCMLToSlate(cml, variableData, videoSubtitlesEnabled);

  const renderElement = useRenderElement();
  const renderLeaf = useRenderTextElement();

  const assetContext = useMemo<AssetContextType>(() => ({ assetManager }), [assetManager]);
  const widgetContext = useMemo<WidgetContextType>(
    () => ({ widgetManager: widgetManager ?? createWidgetManager() }),
    [widgetManager]
  );
  const fillableBlanksContext = useMemo<FillableBlanksContextType>(
    () => ({ enableFillableBlanksV2 }),
    [enableFillableBlanksV2]
  );
  const codeBlockContext = useMemo<CodeBlockContextType>(
    () => ({ codeBlockIndices: getCodeBlockIndices(value) }),
    [value]
  );

  const providers = [
    [AssetContext, assetContext] as Provider<AssetContextType>,
    [CodeBlockContext, codeBlockContext] as Provider<CodeBlockContextType>,
    [FillableBlanksContext, fillableBlanksContext] as Provider<FillableBlanksContextType>,
    [WidgetContext, widgetContext] as Provider<WidgetContextType>,
  ];

  return (
    <div id={contentId} data-testid="cml-viewer" css={styles.viewer}>
      {renderWithProviders(
        providers,
        <SlateReactPresentation value={value} renderElement={renderElement} renderLeaf={renderLeaf} />
      )}
    </div>
  );
};

export default CMLViewer;
