import * as React from 'react';

import { Typography2 } from '@coursera/cds-core';

import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import codeLanguages from 'bundles/cml/legacy/constants/codeLanguages';

import _t from 'i18n!nls/cml';

type Props = {
  languageValue: LanguageType;
};

const CMLCodeLanguageDisplay: React.FC<Props> = ({ languageValue }) => {
  const language = codeLanguages.find((codeLanguage) => codeLanguage.value === languageValue);
  const languageName = language ? language.name : _t('Generic code block');

  return (
    <Typography2 data-testid="cml-code-language-display" component="div" variant="bodySecondary">
      {'< / >'} &nbsp; {languageName}
    </Typography2>
  );
};

export default CMLCodeLanguageDisplay;
