import {
  getWidgetSessionProviderByIdAndCourseId,
  previewWidgetSessionProvider as previewWidgetSessionProviderApi,
} from 'bundles/author-common/utils/AuthoringWidgetSessionAPIUtils';

export const previewWidgetSessionProvider = (id: string, courseId: string, itemId: string) => {
  return previewWidgetSessionProviderApi(id, courseId, itemId).then(({ session }) => session);
};

export const getWidgetSessionProvider = async ({ id, courseId }: { id: string; courseId: string }) => {
  return getWidgetSessionProviderByIdAndCourseId({ id, courseId }).then((response) => {
    return response.elements[0];
  });
};
