import AuthoringExperiments from 'bundles/epic/clients/Authoring';
import { PLATFORM_ONLY_ITEM_TYPES } from 'bundles/item-authoring/shared/platformOnlyItemTypes';
import type { ItemType } from 'bundles/item/types/ItemType';

// https://tools.coursera.org/epic/experiment/HJG-kI6uEeyAw9c5depHlw
export const isItemAuthoringFrameworkEnabledForType = (itemType: ItemType): boolean => {
  if (!itemType) {
    return false;
  }

  return (
    AuthoringExperiments.get('enabledItemAuthoringFrameworkTypes').includes?.(itemType) ||
    PLATFORM_ONLY_ITEM_TYPES.includes(itemType)
  );
};

export const isCompareItemVersionsEnabledForType = (itemType?: ItemType): boolean => {
  if (!itemType) {
    return false;
  }

  return AuthoringExperiments.get('enabledCompareItemVersionTypes').includes?.(itemType);
};

// https://tools.coursera.org/epic/experiment/TODO
export const isExperimentalCourseBuilderItemTypesEnabled = () => {
  return AuthoringExperiments.get('enableExperimentalCourseBuilderItemTypes');
};

export const isEmbedPdfInReadingEnabled = () => {
  return AuthoringExperiments.get('enablePdfEmbedInReading');
};

// https://tools.coursera.org/epic/experiment/CH1RJ_xEEe666w76OVbGYQ
export const isPluginUpdatedUiEnabled = () => {
  return AuthoringExperiments.get('enablePluginUpdatedUi');
};

// https://tools.coursera.org/epic/experiment/mc7TwyjNEe-BZw7QjuOi1Q
export const isV2PluginItemAuthoringEnabled = () => {
  return AuthoringExperiments.get('enableV2PluginItemAuthoring');
};

// https://tools.coursera.org/epic/experiment/TUwVMD7hEe-avw61CxyDOw
export const isV2PluginManagerEnabled = () => {
  return AuthoringExperiments.get('enableV2PluginManager');
};

// https://tools.coursera.org/epic/experiment/JGq5Wz-xEe-GUwr_5yXY-Q
export const isCbCourseReuseEnabled = () => {
  return AuthoringExperiments.get('enableCbCourseReuse');
};

// https://tools.coursera.org/epic/experiment/iG7-ZVamEe-USQr_6ZvHVQ
export const isCourseOutlineSummaryExportsEnabled = () => {
  return AuthoringExperiments.get('enableCourseOutlineSummaryExports');
};

// https://tools.coursera.org/epic/experiment/qiB6wF8SEe-USQr_6ZvHVQ
export const isAiGeneratedGuidedReviewEnabled = () => {
  return AuthoringExperiments.get('enableAiGuidedReview');
};

// https://tools.coursera.org/epic/experiment/scWi0WFyEe-QdhKGhQs4zw
export const isVideoTrimmingEnabled = () => {
  return AuthoringExperiments.get('enableVideoTrimming');
};

// https://tools.coursera.org/epic/experiment/8mfSFnBmEe-USQr_6ZvHVQ
export const isAiWritingAssistantEnabled = () => {
  return AuthoringExperiments.get('enableAiWritingAssistant');
};

// https://tools.coursera.org/epic/experiment/MqmiqHE4Ee-QdhKGhQs4zw
export const isOutlineGenerationFromScratchEnabled = () => {
  return AuthoringExperiments.get('enableOutlineGenerationFromScratch');
};
