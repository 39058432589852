import { useEffect, useState } from 'react';

import EvaluatorSummary from 'bundles/code-evaluator/models/EvaluatorSummary';
import EvaluatorAPIUtils from 'bundles/code-evaluator/utils/EvaluatorAPIUtils';

const useLoadEvaluatorSummary = (evaluatorId: string, receivedEvaluatorSummary?: EvaluatorSummary) => {
  const [evaluatorSummary, setEvaluatorSummary] = useState(new EvaluatorSummary({}));

  useEffect(() => {
    if (!receivedEvaluatorSummary) {
      EvaluatorAPIUtils.getSummary(evaluatorId).then((response) => {
        const newSummary = new EvaluatorSummary(response);
        setEvaluatorSummary(newSummary);
      });
    }
  }, [evaluatorId, receivedEvaluatorSummary]);

  return receivedEvaluatorSummary || evaluatorSummary;
};

export default useLoadEvaluatorSummary;
