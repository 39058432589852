import logger from 'js/app/loggerSingleton';

import type { WidgetMap, WidgetSessionPreview } from 'bundles/cml/shared/types/widgetManager';
import { getWidgetSessionProvider, previewWidgetSessionProvider } from 'bundles/cml/shared/utils/widgetApiUtils';

class WidgetManager {
  cache: Record<string, WidgetMap> = {};

  getWidgetSession = getWidgetSessionProvider;

  previewWidgetSession = async (
    id: string,
    courseId: string,
    itemId: string
  ): Promise<WidgetSessionPreview | undefined> => {
    try {
      const widgetSessionPreview = await previewWidgetSessionProvider(id, courseId, itemId);
      return widgetSessionPreview;
    } catch (e) {
      logger.error(`Error: CMLEditor failed to fetch widget session '${id}'`, e);
      return Promise.reject(e);
    }
  };
}

export const createWidgetManager = () => {
  return new WidgetManager();
};

export type WidgetManagerType = InstanceType<typeof WidgetManager>;
