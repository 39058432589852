import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import type { TableCellElement } from 'bundles/cml/shared/types/elementTypes';

type Props = RenderElementProps & {
  className?: string;
};

const HEADER_ATTRIBUTES = { scope: 'col' };

const TableCell: React.FC<Props> = ({ attributes, children, className, element }) => {
  const { header } = element as TableCellElement;
  return React.createElement(
    header ? 'th' : 'td',
    { ...attributes, className, ...(header ? HEADER_ATTRIBUTES : {}) },
    children
  );
};

export default TableCell;
