/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';

import type { ImageSizes } from 'bundles/cml/shared/types/coreTypes';

const styles = {
  placeholder: css`
    height: 180px;
    max-width: 100%;
    margin-bottom: var(--cds-spacing-300);
    background: var(--cds-color-neutral-background-primary-weak);
  `,
  default: css`
    width: 75%;
  `,
  small: css`
    width: 180px;
  `,
  fullWidth: css`
    width: 100%;
  `,
};

type Props = {
  size: ImageSizes;
};

const ImagePlaceholder: React.FC<Props> = ({ size }) => {
  return <div css={[styles.placeholder, styles[size]]} contentEditable={false} data-testid="cml-image-placeholder" />;
};

export default React.memo(ImagePlaceholder);
