import * as React from 'react';

import PropTypes from 'prop-types';

import 'css!bundles/phoenix/components/__styles__/ProgressBar';

class ProgressBar extends React.Component {
  static propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    percentComplete: PropTypes.number,
  };

  static defaultProps = {
    width: '200px',
    height: '15px',
    percentComplete: 0,
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'height' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { height, width, percentComplete } = this.props;

    return (
      <div className="rc-ProgressBar" style={{ width, height }} data-testid="progress-bar">
        <div className="bgcolor-primary" style={{ width: `${percentComplete}%`, height }} />
      </div>
    );
  }
}

export default ProgressBar;
