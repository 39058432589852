/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import type { Theme } from '@coursera/cds-core';
import { ExternalLinkIcon } from '@coursera/cds-icons';

import LinkRenderer from 'bundles/cml/shared/components/link/Link';

import _t from 'i18n!nls/cml';

type Props = RenderElementProps & React.HTMLAttributes<HTMLAnchorElement>;

const styles = {
  link: css`
    display: inline-flex;
    align-items: baseline;
    flex-wrap: nowrap;
  `,
  icon: css`
    margin-left: var(--cds-spacing-100);
    align-self: center;
  `,
};

const Link = React.forwardRef<HTMLAnchorElement, Props>(({ element, attributes, children, ...linkAttributes }, ref) => {
  return (
    <LinkRenderer ref={ref} element={element} attributes={attributes} css={styles.link} {...linkAttributes}>
      {children}
      <ExternalLinkIcon css={styles.icon} size="small" title={_t('Opens in a new tab')} />
    </LinkRenderer>
  );
});

export default Link;
