/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { Interpolation, Theme } from '@emotion/react';

import * as React from 'react';
import { useImperativeHandle, useRef } from 'react';

import { headerHeight } from 'bundles/widget/components/cds/WidgetInPlaceModalHeader';

export const inPlaceModalStyles = {
  expanded: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 10000;
    background: white !important;
    max-height: 100%;
    max-width: 100%;
    border: none;

    .widget-container {
      position: absolute;
      width: 100vw;
      height: auto;
      max-height: 100vh;
      top: 0;
      left: 0;
    }
  `,
  expandedWidgetIframe: css`
    height: calc(100vh - ${headerHeight}px);
  `,
  unexpanded: css`
    position: relative;
    border: none;
    width: 100%;
  `,
  widgetContainer: css`
    width: 100%;
  `,
  dialog: css`
    padding: 0;
  `,
};

type Props = {
  children: React.ReactNode;
  expanded: boolean;
  modalStyles: {
    modal?: Interpolation<Theme>;
    container?: Interpolation<Theme>;
  };

  header: React.ReactNode;
};

export type WidgetInPlaceModalRef = {
  showWidgetInModal: () => void;
  showWidgetInPlace: () => void;
};

export const WidgetInPlaceModal = React.forwardRef<WidgetInPlaceModalRef | null, Props>(
  ({ children, expanded, modalStyles, header }, ref) => {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const isMounted = useRef<boolean>(false);

    useImperativeHandle(ref, () => {
      const showWidgetInPlace = () => {
        // we use a dialog element as a way to display the expanded iframe with built in focus handling. Because we don't want to remount the iframe, we toggle the dialog into a non-modal mode by first making sure it's not open in any mode, then toggling the open property to open it in the non-modal mode
        dialogRef.current?.close();

        setTimeout(() => {
          if (dialogRef.current != null) {
            dialogRef.current.open = true;
          }
        }, 0);
      };
      const showWidgetInModal = () => {
        // we use a dialog element as a way to display the expanded iframe with built in focus handling. Because we don't want to remount the iframe, we toggle the dialog into a modal mode by first making sure it's not open in any mode, then using .showModal to open it in the modal mode
        dialogRef.current?.close();
        setTimeout(() => {
          dialogRef.current?.showModal();
        }, 0);
      };

      return { showWidgetInPlace, showWidgetInModal };
    });

    return (
      // utilize a dialog element to give us baked in focus handling
      <dialog
        css={[inPlaceModalStyles.dialog, expanded ? inPlaceModalStyles.expanded : inPlaceModalStyles.unexpanded]}
        className="rc-WidgetFrame"
        // We only want dialog semantics when the dialog is expanded
        role={expanded ? 'dialog' : 'presentation'}
        ref={(node) => {
          if (isMounted.current) {
            return;
          }
          if (!dialogRef.current) {
            dialogRef.current = node;
          }

          if (!dialogRef.current?.open) {
            if (expanded) {
              dialogRef.current?.showModal();
            } else {
              dialogRef.current?.show();
            }
          }

          isMounted.current = true;
        }}
      >
        <div
          className="widget-container vertical-box"
          css={[inPlaceModalStyles.widgetContainer, modalStyles.container]}
        >
          {expanded && header}
          {children}
        </div>
      </dialog>
    );
  }
);
