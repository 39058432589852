import { useCallback, useEffect, useState } from 'react';

import { assetToAssetData } from 'bundles/cml/shared/components/asset/assetUtils';
import { useMounted } from 'bundles/cml/shared/hooks/useMounted';
import type { AssetData, ImageAssetData } from 'bundles/cml/shared/types/assetDataTypes';
import type { AssetManager } from 'bundles/cml/shared/types/assetManager';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';

type Options = {
  shouldFetchAsset?: boolean;
  onUpdateAsset?: (asset: Asset) => void;
};

export const useAsset = (
  id: string,
  assetManager: AssetManager,
  { shouldFetchAsset = true, onUpdateAsset }: Options = {}
) => {
  const [asset, setAsset] = useState<Asset | undefined>();
  const [assetData, setAssetData] = useState<AssetData | ImageAssetData | undefined>();

  const mounted = useMounted();

  const updateAssetHandler = useCallback(
    (newAsset?: Asset) => {
      if (!mounted.current || !newAsset) {
        return;
      }

      setAsset(newAsset);
      setAssetData(assetToAssetData(newAsset));
      onUpdateAsset?.(newAsset);
    },
    [mounted, onUpdateAsset]
  );

  useEffect(() => {
    if (id && shouldFetchAsset) {
      const newAsset = assetManager.getAsset(id);
      if (newAsset) {
        updateAssetHandler(newAsset);
      } else {
        assetManager.fetchAsset(id).then(updateAssetHandler);
        assetManager.addAssetListener(id, updateAssetHandler);
      }
    }

    return () => {
      setAsset(undefined);
      assetManager.removeAssetListener(id, updateAssetHandler);
    };
  }, [id, assetManager, updateAssetHandler, shouldFetchAsset]);

  return { asset, assetData };
};
