import _ from 'lodash';

import { XMLSerializer } from 'js/lib/dom';

export const getBlockType = (block: Node) => block.nodeName;

/**
 * Get content for the given block.
 * @param {XMLDOMNode} block CML block
 * @return {string}
 */
export const getInnerContent = function (block: Node): string {
  const s = new XMLSerializer();
  let content = '';

  _.forEach(block.childNodes, function (node) {
    content += s.serializeToString(node);
  });

  return content;
};

/**
 * Get inner text for for the given block.
 */
export const getInnerText = function (block: Node): string {
  let content = '';
  let node: Node;

  for (let i = 0; i < block.childNodes.length; i += 1) {
    node = block.childNodes[i];

    // Get the text from text nodes
    if (node.nodeType === 3) {
      content += node.nodeValue + '\n';
    } else {
      // Traverse everything else
      content += getInnerText(node);

      if (i !== block.childNodes.length - 1) {
        content += '\n';
      }
    }
  }

  return content;
};
