/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { RenderElementProps } from 'slate-react';

import createLoadableComponent from 'js/lib/createLoadableComponent';

import type { FillableBlankElement } from 'bundles/cml/shared/types/elementTypes';

const CMLFillableBlank = createLoadableComponent(
  () => import('bundles/cml/shared/components/fillableBlank/CMLFillableBlank')
);

const styles = {
  root: css`
    display: inline-block;
  `,
};

type Props = RenderElementProps &
  React.HTMLAttributes<HTMLElement> & {
    label?: string;
    enableFillableBlanksV2?: boolean;
  };

const FillableBlank: React.FC<Props> = ({
  element,
  attributes,
  children,
  className,
  label,
  enableFillableBlanksV2,
  ...htmlAttributes
}) => {
  const fillableBlank = element as FillableBlankElement;
  const { innerText, uuid = '' } = fillableBlank;

  const placeholder = label || innerText;

  return (
    <span {...attributes} className="rc-FillableBlank cml-fillable-blank" {...htmlAttributes} css={styles.root}>
      <span contentEditable={false}>
        <CMLFillableBlank
          uuid={uuid}
          placeholder={placeholder}
          enableFillableBlanksV2={enableFillableBlanksV2}
          className={className}
        />
      </span>
      {children}
    </span>
  );
};

export default FillableBlank;
