import * as React from 'react';

import type { RenderLeafProps } from 'slate-react';

type Props = RenderLeafProps & {
  className?: string;
};

const Text: React.FC<Props> = (props) => {
  const { leaf, attributes, className } = props;
  let children = props.children;

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.monospace) {
    children = <var>{children}</var>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }

  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }

  return (
    <span {...attributes} className={className}>
      {children}
    </span>
  );
};

export default Text;
